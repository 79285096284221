
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getStoryblokLink } from './../../utils/storyblok-helper'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpBrandGrid',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      return (this.blok.items || []).map((item) => {
        const link = getStoryblokLink(item.link)

        const isExternalTarget =
          link.href !== null &&
          typeof link.target === 'string' &&
          link.target.toLowerCase() === '_blank'

        let props

        if (link.href) {
          if (isExternalTarget) {
            props = {
              href: link.href,
              target: link.target,
              rel: link.target !== null ? 'nofollow noopener' : null,
            }
          } else {
            props = {
              to: link.href,
            }
          }
        }

        return {
          component:
            link.href === null ? 'div' : isExternalTarget ? 'a' : 'NuxtLink',
          icon: 'icon-brand-' + item.icon,
          label: item.label,
          props,
        }
      })
    },
    label() {
      return this.blok.label || ''
    },
  },

  mounted() {
    const itemAnimation = gsap.fromTo(
      this.$refs.items,
      {
        opacity: 0.25,
        y: '100px',
      },
      {
        opacity: 1,
        y: 0,
        ease: 'power1',
        stagger: 0.1,
      }
    )

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top bottom',
      end: 'bottom bottom-=30%',
      scrub: true,
      animation: itemAnimation,
    })
  },
}
