
import PrimaryNavigation from './../primary-navigation/primary-navigation.vue'
import SecondaryNavigation from './../secondary-navigation/secondary-navigation.vue'

export default {
  name: 'Header',

  components: {
    PrimaryNavigation,
    SecondaryNavigation,
  },

  props: {
    homeLink: {
      type: Object,
      required: false,
      default: null,
    },
    previousLink: {
      type: Object,
      required: false,
      default: null,
    },
    currentPageTitle: {
      type: String,
      required: false,
      default: null,
    },
    nextLink: {
      type: Object,
      required: false,
      default: null,
    },
    primaryNavigationLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    theme: {
      type: String,
      required: true,
    },
    languageLabel: {
      type: String,
      required: false,
      default: null,
    },
    languageAlternateUrl: {
      type: String,
      required: false,
      default: null,
    },
    languageAlternateHomeLink: {
      type: String,
      required: false,
      default: null,
    },
    languageSwitchTitle: {
      type: String,
      required: false,
      default: null,
    },
    absolutePosition: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    renderPrimaryMenuLinks() {
      return this.previousLink === null && this.nextLink === null
    },
    renderLanguageSwitch() {
      return (
        (this.languageAlternateUrl || this.languageAlternateHomeLink) &&
        this.languageLabel
      )
    },
  },

  methods: {
    openMenu() {
      this.$emit('click-navigation')
    },
  },
}
