
import { getStoryblokLink } from './../../utils/storyblok-helper'

export default {
  name: 'CmpQuoteBlock',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      return this.blok.text || null
    },
    sourceInformation() {
      return this.blok.source_information || null
    },
    sourceLink() {
      return Object.assign(getStoryblokLink(this.blok.source_link), {
        label: 'Quelle', // TODO: translate
      })
    },
  },
}
