
export default {
  name: 'WcmpTextList',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },

    items() {
      return this.blok.items || {}
    },
  },
}
