
export default {
  name: 'CswExternalService',

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    service: {
      type: String,
      required: false,
      default: 'facebook-comb',
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    iconComponentTag() {
      return (
        'icon-' +
        ([
          'facebook-comb',
          'instagram-comb',
          'github-comb',
          'linkedin-comb',
        ].includes(this.service)
          ? this.service
          : 'facebook-comb')
      )
    },
    isExternalLink() {
      return (
        this.href !== null &&
        typeof this.target === 'string' &&
        this.target.toLowerCase() === '_blank'
      )
    },
  },
}
