
export default {
  name: 'CswLanguageSwitch',

  props: {
    href: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
    isAlternate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
