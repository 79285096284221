
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getStoryblokLink } from './../../utils/storyblok-helper'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpLinkTileGrid',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    links() {
      return this.blok.items.map((item) => {
        const link = getStoryblokLink(item.link)
        let props

        if (link.href) {
          props = {
            to: link.href,
          }
        } else {
          props = {}
        }

        return {
          component: link.href === null ? 'div' : 'NuxtLink',
          props,
          label: item.link_label,
        }
      })
    },
  },

  mounted() {
    const itemAnimation = gsap.fromTo(
      this.$refs.items,
      {
        opacity: 0.25,
        y: '100px',
      },
      {
        opacity: 1,
        y: 0,
        ease: 'power1',
        stagger: 0.1,
      }
    )

    ScrollTrigger.create({
      trigger: this.$el,
      start: 'top bottom',
      end: 'bottom bottom-=30%',
      scrub: true,
      animation: itemAnimation,
    })
  },
}
