
export default {
  name: 'CswModalContent',

  props: {
    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeLabel: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer
    },
  },

  methods: {
    close() {
      this.$emit('close')
    },
  },
}
