
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'Notification',

  props: {
    type: {
      type: String,
      required: false,
      default: 'default',
      validator(value) {
        return ['default', 'important'].includes(value)
      },
    },
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    expandLabel: {
      type: String,
      required: true,
    },
    closeLabel: {
      type: String,
      required: true,
    },
    /**
    {
      label: String,
      link: {
        href: String | null,
        target: String | null
      },
      hide: String
    }
    */
    primaryButton: {
      type: Object,
      required: false,
      default: null,
    },
    /**
    {
      label: String,
      link: {
        href: String | null,
        target: String | null
      },
      appearance: String,
      hide: String
    }
    */
    secondaryButton: {
      type: Object,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isExpanded: false,
    }
  },

  computed: {
    classes() {
      return {
        'notification--important': this.type === 'important',
        'notification--expanded': this.isExpanded,
      }
    },
  },

  methods: {
    onPrimaryCtaClicked(event) {
      this.handleCtaClick(event, this.primaryButton)
    },
    onSecondaryCtaClicked(event) {
      this.handleCtaClick(event, this.secondaryButton)
    },
    handleCtaClick(event, button) {
      if (['current', 'session', 'permanent'].includes(button.hide)) {
        event.preventDefault()
        this.$emit('hide', button.hide)
        if (typeof button.link?.clickHandler === 'function') {
          button.link.clickHandler()
        }
      }
    },
    expand() {
      this.isExpanded = true
      this.$nextTick(() => {
        ScrollTrigger.refresh()
      })
    },
    close() {
      let mode = null

      if (this.secondaryButton?.hide) {
        mode = this.secondaryButton?.hide
      } else if (this.primaryButton?.hide) {
        mode = this.primaryButton?.hide
      }

      this.$emit('hide', mode)
    },
  },
}
