import { locales as localeConfigs } from './../app.config'

export const getLocaleConfigByLocaleSlug = (localeSlug) => {
  return (
    localeConfigs.find((c) => c.slug === String(localeSlug).toLowerCase()) ||
    null
  )
}

export default {
  getLocaleConfigByLocaleSlug,
}
