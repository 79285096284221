
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getStoryblokLink } from './../../utils/storyblok-helper'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpFeatureListHero',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    servicesHeadline() {
      return this.blok.services_headline || ''
    },

    services() {
      return (this.blok.services || []).map((service) => {
        return {
          icon: service.icon,
          linkLabel: service.label || '',
          link: getStoryblokLink(service.link),
        }
      })
    },
  },

  mounted() {
    document.fonts.ready.then(() => {
      this.createAnimationDesktop()

      this.$slots.default.forEach((vnode) => {
        vnode.componentInstance.startAnimation()
      })
    })
  },

  methods: {
    createAnimationDesktop() {
      const svg = this.$refs.svg
      const content = this.$refs.content

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            markers: false,
          },
        })
        .fromTo(
          svg,
          { y: 0, opacity: 1 },
          { y: '-100vh', opacity: 0, ease: 'none' }
        )

      const serviceHeadline = this.$refs.serviceHeadline
      const features = () => {
        const el = []
        this.$refs.features.forEach((feature) => {
          el.push(feature.$el ? feature.$el : feature)
        })
        return el
      }
      const linksWrapper = this.$refs.linksWrapper

      gsap
        .timeline({
          scrollTrigger: {
            trigger: linksWrapper,
            start: 'top bottom',
            end: 'bottom bottom-=15%',
            scrub: true,
            markers: false,
          },
        })
        .fromTo(
          features(),
          {
            y: '15vh',
          },
          {
            y: 0,
            ease: 'power1',
            duration: 0.6,
            stagger: 0.5,
          },
          0
        )
        .fromTo(
          serviceHeadline,
          {
            y: '50vh',
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            ease: 'power1',
            duration: 5.5,
          },
          0
        )

      const slotElements = () => {
        return this.$slots.default[0].elm.children
      }

      const tlInitial = gsap.timeline({ repeat: 0 })

      tlInitial
        .fromTo(
          svg,
          {
            drawSVG: '50% 50%',
          },
          {
            drawSVG: '100%',
            ease: 'none',
            duration: 4,
          },
          0.3
        )
        .fromTo(
          [...slotElements()].slice(1),
          {
            yPercent: 100,
            opacity: 0,
          },
          {
            yPercent: 0,
            opacity: 1,
            ease: 'power1',
            duration: 0.5,
            stagger: 0.2,
          },
          0.3
        )
    },
  },
}
