
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getStoryblokLink } from './../../utils/storyblok-helper'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpVersusTeasers',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },
    teasers() {
      return (this.blok.teasers || []).map((teaser) => {
        return {
          headline: teaser.headline,
          text: teaser.text || null,
          link: getStoryblokLink(teaser.link),
          linkLabel: teaser.link_label || '',
        }
      })
    },
  },

  mounted() {
    this.createAnimationDesktop()
  },

  methods: {
    createAnimationDesktop() {
      const content = this.$refs.content
      const headline = this.$refs.headline.$el
      const teasers = []
      this.$refs.teasers.forEach((teaser) => {
        teasers.push(teaser.$el)
      })

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top bottom',
            end: 'bottom bottom+=10%',
            scrub: 1,
            markers: false,
            invalidateOnRefresh: true,
          },
        })
        .fromTo(
          headline,
          { y: () => '20vh', opacity: 1 },
          { y: 0, opacity: 1, duration: 2, ease: 'none' },
          0
        )
        .fromTo(
          teasers,
          { y: () => '20vh', opacity: 1 },
          { y: 0, opacity: 1, duration: 1, ease: 'none', stagger: 0.5 },
          1
        )
    },
  },
}
