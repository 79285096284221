
import { useStoryblokApi } from '@storyblok/nuxt'
import { getAssetUrl } from './../../utils/storyblok-helper'

export default {
  name: 'CmpMediaGrid',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mediaList: [],
    }
  },

  computed: {
    size() {
      const validSizes = ['m', 's']
      return validSizes.includes(this.blok.size)
        ? this.blok.size
        : validSizes[0]
    },

    classes() {
      return {
        'media-grid': true,
        'media-grid--size-m': this.size === 'm',
        'media-grid--size-s': this.size === 's',
      }
    },
  },

  watch: {
    'blok.media'() {
      this.setMediaList()
    },
  },

  created() {
    this.setMediaList()
  },

  methods: {
    setMediaList() {
      this.mediaList = (this.blok.media || [])
        .map((m, index) => {
          let mediaObject = null

          let caption = null
          if (!this.blok.hideCaptions) {
            caption = this.getCaption(
              m.caption || null,
              m.source_landscape?.title || null
            )
          }

          if (m.component === 'cmp-media-grid-image' && m.source_landscape) {
            mediaObject = {
              key: m.source_landscape?.filename + ' ' + index || null,
              type: 'image',
              src: m.source_landscape?.filename || null,
              srcset: this.getSourceSet(m.source_landscape?.filename || null),
              alt: m.source_landscape?.alt || null,
              title: m.source_landscape?.title || null,
              sizes: this.getSizes(this.size),
              caption,
            }
          }
          return mediaObject
        })
        .filter((m) => m !== null)
    },

    getSourceSet(url) {
      const imageSize = [
        300, 375, 640, 750, 828, 1080, 1200, 1920, 2048, 3000, 3840,
      ]
      if (url) {
        return imageSize
          .map(
            (size) =>
              getAssetUrl(url, size + `x` + Math.round(size * 0.5625)) +
              ' ' +
              size +
              'w'
          )
          .join(', ')
      }
      return null
    },

    getSizes(size) {
      if (size === 's') {
        return '(min-width: 2176px) 629px, (min-width: 1440px) calc((100vw - (2 * 128px) - (2 * 16px)) / 3), (min-width: 1280px) calc((100vw - (2 * 64px) - (2 * 16px)) / 3), (min-width: 1024px) calc((100vw - (2 * 32px) - (2 * 16px)) / 3), (min-width: 768px) calc((100vw - (2 * 32px) - 16px) / 2), (min-width: 640px) calc((100vw - (2 * 16px) - 16px) / 2), calc(100vw - (2 * 16px))'
      } else {
        return '(min-width: 2176px) 952px, (min-width: 1440px) calc((100vw - (2 * 128px) - 16px) / 2), (min-width: 1280px) calc((100vw - (2 * 64px) - 16px) / 2), (min-width: 768px) calc((100vw - (2 * 32px) - 16px) / 2), (min-width: 640px) calc((100vw - (2 * 16px) - 16px) / 2), calc(100vw - (2 * 16px))'
      }
    },

    getCaption(blokCaption, title) {
      const storyblokApi = useStoryblokApi()
      let caption = title || null

      const isEmptyCaption = (caption) => {
        return (
          !Array.isArray(caption?.content) ||
          (caption.content.length === 1 &&
            caption.content[0].content === undefined)
        )
      }

      if (!isEmptyCaption(blokCaption)) {
        caption = storyblokApi.richTextResolver.render(blokCaption)
      }

      return caption
    },
  },
}
