
export default {
  name: 'CswBackToTopLink',

  props: {
    label: {
      type: String,
      required: true,
    },
  },
}
