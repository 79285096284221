
import { getAssetUrl, getStoryblokLink } from '../../utils/storyblok-helper'

export default {
  name: 'PanoramaHero',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },
    text() {
      return this.blok.text || ''
    },
    primaryLink() {
      let content = null

      if (this.blok.primary_link_label !== '') {
        content = {
          link: getStoryblokLink(this.blok.primary_link) || '',
          icon: this.blok.primary_link_icon || '',
          label: this.blok.primary_link_label || '',
        }
      }
      return content
    },

    secondaryLink() {
      let content = null

      if (this.blok.secondary_link_label !== '') {
        content = {
          link: getStoryblokLink(this.blok.secondary_link) || '',
          icon: this.blok.secondary_link_icon || '',
          label: this.blok.secondary_link_label || '',
        }
      }
      return content
    },
    defaultImage() {
      let defaultImage = null

      if (
        this.blok.image_source_landscape &&
        this.blok.image_source_landscape.filename
      ) {
        defaultImage = {
          src: getAssetUrl(
            this.blok.image_source_landscape.filename,
            '1440x810/filters:quality(10)'
          ),
          alt: this.blok.image_source_landscape.alt,
        }
      }

      return defaultImage
    },
    landscapeSources() {
      let sources = []

      if (
        this.blok.image_source_landscape &&
        this.blok.image_source_landscape.filename
      ) {
        const getResolvedAssetUrl = (width, height = null) => {
          height = height || Math.round((9 / 16) * width)
          return getAssetUrl(
            this.blok.image_source_landscape.filename,
            width + 'x' + height + '/filters:quality(80)'
          )
        }

        // TODO: maybe also add different qualities here
        sources = [
          {
            media: '(max-width: 374px)',
            srcset: getResolvedAssetUrl(300),
          },
          {
            media: '(min-width: 375px)',
            srcset: getResolvedAssetUrl(340),
          },
          {
            media: '(min-width: 425px)',
            srcset: getResolvedAssetUrl(626),
          },
          {
            media: '(min-width: 768px)',
            srcset: getResolvedAssetUrl(910),
          },
          {
            media: '(min-width: 1024px)',
            srcset: getResolvedAssetUrl(1145),
          },
          {
            media: '(min-width: 1280px)',
            srcset: getResolvedAssetUrl(1265),
          },
          {
            media: '(min-width: 1440px)',
            srcset: getResolvedAssetUrl(1652),
          },
        ]
      }

      return sources.reverse()
    },
    portraitSources() {
      let sources = []

      if (this.blok.image_source_portrait || this.blok.image_source_landscape) {
        const getResolvedAssetUrl = (width) => {
          const height = Math.round((16 / 9) * width)
          const image =
            this.blok.image_source_portrait || this.blok.image_source_landscape
          return getAssetUrl(
            image.filename,
            width + 'x' + height + '/filters:quality(80)'
          )
        }

        sources = [
          {
            media: '(max-width: 374px) and (orientation: portrait)',
            srcset: getResolvedAssetUrl(300),
          },
          {
            media:
              '(min-width: 375px) and (max-width: 424px) and (orientation: portrait)',
            srcset: getResolvedAssetUrl(340),
          },
          {
            media:
              '(min-width: 425px) and (max-width: 767px) and (orientation: portrait)',
            srcset: getResolvedAssetUrl(626),
          },
          {
            media:
              '(min-width: 768px) and (max-width: 1023px) and (orientation: portrait)',
            srcset: getResolvedAssetUrl(910),
          },
        ]
      }

      return sources.reverse()
    },
  },
}
