
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { MorphSVGPlugin } from '@gsap/shockingly/dist/MorphSVGPlugin'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, MorphSVGPlugin)

export default {
  name: 'CmpFlexibleTextContent',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },
  },

  mounted() {
    // TODO: resize animations
    // https://greensock.com/forums/topic/27741-animation-not-playing-correctly-after-resizing-the-window/
    document.fonts.ready.then(() => {
      this.createAnimationDesktop()
    })
  },

  methods: {
    createAnimationDesktop() {
      const slotElements = () => {
        const el = []
        if (this.$slots.default) {
          this.$slots.default.forEach((vNode) => {
            el.push(vNode.elm)
          })
        }
        return el
      }

      const tlTextArea = gsap.timeline().fromTo(
        slotElements(),
        {
          y: '10vh',
        },
        {
          y: 0,
          ease: 'none',
          duration: 0.5,
          stagger: 0.5,
        },
        0
      )

      ScrollTrigger.create({
        trigger: this.$refs.textArea,
        start: 'top bottom',
        end: 'bottom bottom',
        animation: tlTextArea,
        scrub: true,
        markers: false,
      })

      const svgTop = this.$refs.svgTop
      const svgTop1 = this.$refs.svgTop1
      const svgTop2 = this.$refs.svgTop2
      const svgTop3 = this.$refs.svgTop3
      const svgTop4 = this.$refs.svgTop4
      const svgTop5 = this.$refs.svgTop5
      const svgTop6 = this.$refs.svgTop6

      this.createCombGroupAnimation(svgTop, [
        svgTop1,
        svgTop2,
        svgTop3,
        svgTop5,
        svgTop4,
        svgTop6,
      ])

      const svgBottom = this.$refs.svgBottom
      const svgBottom1 = this.$refs.svgBottom1
      const svgBottom2 = this.$refs.svgBottom2
      const svgBottom3 = this.$refs.svgBottom3
      const svgBottom4 = this.$refs.svgBottom4
      const svgBottom5 = this.$refs.svgBottom5
      const svgBottom6 = this.$refs.svgBottom6

      this.createCombGroupAnimation(svgBottom, [
        svgBottom1,
        svgBottom2,
        svgBottom3,
        svgBottom4,
        svgBottom5,
        svgBottom6,
      ])
    },

    createCombGroupAnimation(svg, combPaths) {
      let direction = 1
      let masterTimelines = []

      const cswThemePrimaryAccentColor = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-primary-accent-color')
        .trim()

      const cswThemeBrandColor = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-brand-color')
        .trim()

      const tlSvg = gsap.timeline().fromTo(
        combPaths,
        {
          y: '50vh',
          opacity: 0,
          stroke: 'rgb(' + cswThemeBrandColor + ')',
        },
        {
          y: 0,
          duration: 3,
          opacity: 0.7,
          stroke: 'rgb(' + cswThemeBrandColor + ')',
          stagger: {
            each: 1,
            onUpdate() {
              if (direction === -1) {
                const found = masterTimelines.find(
                  (obj) => obj.el === this.targets()[0]
                )
                if (found !== undefined) {
                  found.masterTl.pause()

                  gsap
                    .timeline({
                      onComplete() {
                        found.masterTl.kill()
                      },
                    })
                    .to(this.targets()[0], {
                      opacity: 0.7,
                      stroke: 'rgb(' + cswThemeBrandColor + ')',
                      duration: 1,
                    })
                }

                masterTimelines = masterTimelines.filter(
                  (obj) => obj.el !== this.targets()[0]
                )
              }
            },
            onComplete() {
              const found = masterTimelines.find(
                (obj) => obj.el === this.targets()[0]
              )

              if (found === undefined) {
                function getRand(min, max) {
                  return Math.random() * (max - min) + min
                }

                const itemTl = gsap.timeline({ paused: false })

                let bootstrap = 0

                itemTl.to(this.targets()[0], {
                  ease: 'linear',
                  duration: () => 0.2,
                  opacity: () => {
                    let opacity

                    if (bootstrap === 0) {
                      opacity = 1
                    } else if (bootstrap === 1) {
                      opacity = gsap.utils.random(0.25, 0.5, 0.01)
                    } else {
                      opacity = gsap.utils.random(0.25, 0.7, 0.01)
                    }

                    return opacity
                  },
                  stroke: () => {
                    let color

                    if (bootstrap === 0) {
                      color = cswThemePrimaryAccentColor
                    } else if (bootstrap === 1) {
                      color = cswThemeBrandColor
                    } else {
                      color =
                        Math.round(getRand(0, 2)) === 0
                          ? cswThemePrimaryAccentColor
                          : cswThemeBrandColor
                    }

                    return 'rgb(' + color + ')'
                  },
                  onComplete() {
                    bootstrap++

                    itemTl
                      .duration(gsap.utils.random(1, 3, 0.1))
                      .invalidate()
                      .play(0)
                  },
                })

                masterTimelines.push({
                  el: this.targets()[0],
                  masterTl: itemTl,
                })
              }
            },
          },
          ease: 'power4',
        },
        0
      )

      ScrollTrigger.create({
        trigger: svg,
        start: 'top bottom',
        end: 'top top+=5%',
        animation: tlSvg,
        scrub: true,
        markers: false,
        onUpdate(self) {
          direction = self.direction
        },
      })
    },
  },
}
