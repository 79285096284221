import crypto from 'crypto'

export default function ({ error, query, $storyapi, isStatic, isDev }) {
  if (isStatic === false && isDev === false) {
    const spaceId = query['_storyblok_tk[space_id]']
    const timestamp = query['_storyblok_tk[timestamp]']
    const token = query['_storyblok_tk[token]']

    const validationString =
      spaceId + ':' + $storyapi.accessToken + ':' + timestamp
    const validationToken = crypto
      .createHash('sha1')
      .update(validationString)
      .digest('hex')

    if (
      token !== validationToken ||
      timestamp <= Math.floor(Date.now() / 1000) - 3600
    ) {
      error({
        statusCode: 401,
        message: 'You do not have sufficient permission to view this page.',
      })
    }
  }
}
