
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getStoryblokLink } from './../../utils/storyblok-helper'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpExtendedFeatureList',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    items() {
      return (this.blok.items || []).map((item) => {
        return {
          icon: item.icon,
          headline: item.headline || '',
          text: item.text || {},
          linkLabel: item.link_label || '',
          link: getStoryblokLink(item.link),
        }
      })
    },
    elementId() {
      return this.blok.element_id || null
    },
  },

  mounted() {
    this.createAnimationDesktop()
  },

  methods: {
    createAnimationDesktop() {
      const content = this.$refs.content
      const items = this.$refs.items

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: 1,
            markers: false,
          },
        })
        .fromTo(
          items,
          {
            yPercent: 100,
          },
          {
            yPercent: 0,
            ease: 'none',
            duration: 1,
            stagger: 0.5,
          },
          0
        )
    },
  },
}
