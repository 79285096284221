
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpExtendedFeatureList',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      elementCount: null,
      gsapMatchMedia: null,
    }
  },

  computed: {
    items() {
      return (this.blok.items || []).map((item) => {
        return {
          headline: item.headline || '',
        }
      })
    },
  },

  beforeDestroy() {
    this.removeAnimation()
  },

  mounted() {
    this.gsapMatchMedia = gsap.matchMedia()

    this.gsapMatchMedia.add('(min-width: 1024px)', (context) => {
      const panelsContainer = this.$refs.items
      const panels = gsap.utils.toArray(panelsContainer.children)

      this.elementCount = panels.length

      this.$nextTick(() => {
        context.add('addSliderAnimation', () => {
          const scrollTween = gsap.to(panels, {
            xPercent: -100 * (panels.length - 1),
            ease: 'none',
            scrollTrigger: {
              trigger: panelsContainer,
              start: 'top top',
              pin: true,
              pinSpacing: true,
              scrub: 1,
              snap: {
                snapTo: 1 / (panels.length - 1),
                inertia: false,
                duration: { min: 0.1, max: 0.1 },
              },
              end: () =>
                '+=' + (panelsContainer.offsetWidth - window.innerWidth),
            },
          })

          panels.forEach((panel, index) => {
            const panelContent = panel.querySelectorAll(
              '.content-slider-item__content'
            )

            const tlPanel = gsap.timeline()

            if (index === 0) {
              tlPanel.to(
                panelContent,
                {
                  opacity: 1,
                  ease: 'none',
                  duration: 0.5,
                },
                0
              )
            } else {
              tlPanel.fromTo(
                panelContent,
                {
                  opacity: 0.25,
                },
                {
                  opacity: 1,
                  ease: 'none',
                  duration: 0.5,
                },
                0
              )
            }

            tlPanel.fromTo(
              panelContent,
              {
                opacity: 1,
              },
              {
                opacity: 0.25,

                ease: 'none',
                duration: 0.5,
              }
            )

            ScrollTrigger.create({
              trigger: panel,
              start: 'left right',
              end: 'right left',
              scrub: true,
              containerAnimation: scrollTween,
              animation: tlPanel,
            })
          })

          ScrollTrigger.create({
            trigger: this.$refs.combWrapper,
            start: 'top top',
            pin: true,
            pinSpacing: true,
            scrub: true,
            end: () => '+=' + (panelsContainer.offsetWidth - window.innerWidth),
            animation: gsap.to(this.$refs.comb, { x: 0 }),
          })
        })

        context.addSliderAnimation()
      })

      return () => {
        this.elementCount = null
      }
    })
  },

  methods: {
    removeAnimation() {
      this.gsapMatchMedia.revert()
      this.gsapMatchMedia = null
    },
  },
}
