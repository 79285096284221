
import { getAssetUrl } from './../../utils/storyblok-helper'

export default {
  name: 'CmpLargeDecoratedImage',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      source: null,
    }
  },

  computed: {
    sourceSet() {
      const imageSize = [375, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]
      return imageSize
        .map(
          (size) =>
            getAssetUrl(
              this.blok.source_landscape.filename,
              size + `x` + Math.round(size * 0.5625)
            ) +
            ' ' +
            size +
            'w'
        )
        .join(', ')
    },
    sizes() {
      return '(min-width: 2176px) 1887px, (min-width: 1440px) calc(100vw - ((100vw - (2 * 128px + 11 * 16px)) / 12) - 128px - 16px), (min-width: 1280px) calc(100vw - ((100vw - (2 * 64px + 11 * 16px)) / 12) - 64px - 16px), (min-width: 768px) calc(100vw - ((100vw - (2 * 32px + 11 * 16px)) / 12) - 32px - 16px), calc(100vw - ((100vw - (2 * 16px + 11 * 16px)) / 12) - 16px - 16px)'
    },
  },
}
