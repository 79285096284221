
export default {
  name: 'ArticleTeaser',

  props: {
    headline: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    formattedDate: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: true,
    },
    imageLazyload: {
      type: Boolean,
      required: false,
      default: false,
    },
    imageSources: {
      type: Array,
      required: false,
      default: () => [],
    },
    imageAlt: {
      type: String,
      required: false,
      default: '',
    },
    imagePosition: {
      type: String,
      required: false,
      default: 'left',
      validator: (value) => ['left', 'top', 'right'].includes(value),
    },
  },

  computed: {
    classNames() {
      return {
        'article-teaser--image-left': this.imagePosition === 'left',
        'article-teaser--image-right': this.imagePosition === 'right',
      }
    },
    defaultImageSource() {
      let src = null

      const found = this.imageSources.find((source) => source.media === null)

      if (found === undefined) {
        if (this.imageSources.length) {
          src = this.imageSources[0].srcset
        }
      } else {
        src = found.srcset
      }
      return src
    },
  },
}
