export const hiddenNotificationIds = (state) => {
  return state.hidden
    .filter((d) => {
      let isHidden = false
      const now = new Date().getTime()
      const differenceInMilliseconds = now - d.createdAt

      if (d.mode === 'session') {
        isHidden = differenceInMilliseconds < 24 * 3600 * 1000 // 1 Tag
      } else if (d.mode === 'permanent') {
        isHidden = differenceInMilliseconds < 7 * 24 * 3600 * 1000 // 7 Tage
      } else if (d.mode === 'current') {
        isHidden = differenceInMilliseconds < 100 // 100ms for runtime
      }

      return isHidden
    })
    .map((d) => d.id)
}

export default {
  hiddenNotificationIds,
}
