export const getSanitizedFullSlug = (slug) => {
  let url = slug

  url = url.indexOf('/') === 0 ? url : '/' + url
  url = url.replace(/\/$/, '')

  return url
}

export const hasWindowMethodInHref = (href) => {
  return typeof href === 'string' && href.startsWith('csw:')
}

export const callWindowMethodByHref = (href) => {
  if (typeof href === 'string' && href.startsWith('csw:')) {
    const methodName = href.replace('csw:', '')
    if (typeof window[methodName] === 'function') {
      window[methodName]()
    }
  }
}

export default {
  hasWindowMethodInHref,
  callWindowMethodByHref,
  getSanitizedFullSlug,
}
