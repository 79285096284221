import { assetDomain, storyblokAssetDomain } from './../app.config'
import { getLocaleConfigByLocaleSlug } from './locale-helper'
import {
  getSanitizedFullSlug,
  hasWindowMethodInHref,
  callWindowMethodByHref,
} from './url-helper'

export const getStoryblokLink = (blokLink) => {
  const link = {
    href: null,
    target: null,
    isExternal: false,
    anchor: blokLink.anchor !== '' ? blokLink.anchor : null,
    clickHandler: () => {},
  }

  if (blokLink.linktype === 'story' && blokLink.id) {
    let url

    if (blokLink.story !== undefined) {
      url = blokLink.story.url
    } else {
      url = blokLink.cached_url !== undefined ? blokLink.cached_url : link.href
    }

    if (url === 'home') {
      // TODO can be deleted maybe for this project?
      url = ''
    }

    url = getSanitizedFullSlug(url)
    link.href = url
  } else if (blokLink.url !== undefined && blokLink.url !== '') {
    link.href = blokLink.url
    link.target = '_blank'
    link.isExternal = true
  }

  if (hasWindowMethodInHref(link.href)) {
    const linkHref = link.href
    link.clickHandler = () => callWindowMethodByHref(linkHref)
    link.href = null
    link.target = null
    link.isExternal = false
  }

  return link
}

export const getAssetUrl = (originSource, option = '') => {
  const imageService = assetDomain
  const path = originSource.replace(storyblokAssetDomain, '')
  return imageService + path + '/m/' + option
}

export const getImageSrcset = (
  originSource,
  { maxPixelDensity = 2, width = 0, height = 0 }
) => {
  const sources = []

  for (let i = 1; i <= maxPixelDensity; i++) {
    let source = getAssetUrl(originSource, width * i + 'x', height * i)

    if (maxPixelDensity > 1) {
      source += ' ' + i.toString() + 'x'
    }

    sources.push(source)
  }

  return sources.join(', ')
}

export const getImageSrc = (
  originSource,
  { pixelDensity = 1, width = 0, height = 0 }
) => {
  return getAssetUrl(
    originSource,
    width * pixelDensity + 'x',
    height * pixelDensity
  )
}

export const getLocaleConfigByPath = (path) => {
  return getLocaleConfigByLocaleSlug(path.split('/')[0])
}

export default {
  getStoryblokLink,
  getImageSrcset,
  getImageSrc,
  getLocaleConfigByPath,
}
