
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { MorphSVGPlugin } from '@gsap/shockingly/dist/MorphSVGPlugin'

gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, MorphSVGPlugin)

export default {
  name: 'CmpSteppedContent',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    label() {
      return this.blok.label || ''
    },

    items() {
      let items = []

      items = this.blok.items.map((item) => {
        return {
          headline: item.headline || null,
          headlineTag: item.headline_tag || 'div',
          text: item.text || null,
        }
      })

      return items
    },
  },

  mounted() {
    this.createAnimations()
  },

  methods: {
    createAnimations() {
      const timelines = []

      const intro = this.$refs.intro
      const introHorizontalPath = this.$refs.introHorizontalPath
      const introVerticalPath = this.$refs.introVerticalPath

      timelines.push(
        gsap
          .timeline({
            scrollTrigger: {
              trigger: intro,
              start: 'top top+=30%',
              end: 'bottom top+=30%',
              scrub: true,
            },
          })
          .fromTo(
            introHorizontalPath,
            { drawSVG: '0%' },
            { duration: 0.5, drawSVG: '100%' }
          )
          .fromTo(
            introVerticalPath,
            { drawSVG: '0%' },
            { duration: 2, drawSVG: '100%' }
          )
      )

      this.items.forEach((el, index) => {
        const header = this.$refs.header[index]

        const combPassive = this.$refs.comb1[index]
        const combActive = this.$refs.comb2[index]
        const combActivePath = this.$refs.comb2Path[index]
        const combFilled = this.$refs.comb3[index]

        const numbering = this.$refs.numbering[index]

        const checkmark = this.$refs.checkmark[index]
        const checkmarkSinglePath = this.$refs.checkmarkSinglePath[index]
        const checkmarkDoublePath = this.$refs.checkmarkDoublePath[index]

        const cswThemePrimaryAccentColorRgb = getComputedStyle(this.$el)
          .getPropertyValue('--csw-theme-primary-accent-color')
          .trim()

        const cswThemePrimaryContrastColorRgb = getComputedStyle(this.$el)
          .getPropertyValue('--csw-theme-primary-contrast-color')
          .trim()

        timelines.push(
          gsap
            .timeline({
              scrollTrigger: {
                trigger: header,
                start: 'top top+=30%',
                end: 'bottom top+=30%',
                scrub: true,
              },
            })
            .fromTo(
              combActivePath,
              { drawSVG: '50% 50%' },
              { duration: 1, drawSVG: '100%' },
              0
            )
            .fromTo(
              numbering,
              {
                autoAlpha: 0.5,
              },
              {
                duration: 1,
                autoAlpha: 1,
              },
              0
            )
            .fromTo(checkmark, { scale: 0 }, { duration: 1, scale: 1 }, 0)
            .to(combPassive, { autoAlpha: 0, duration: 0 })
            .to(combActive, { rotation: 180, duration: 1 }, 1)
            .fromTo(
              combFilled,
              { scale: 0 },
              { rotation: 180, duration: 1, scale: 1 },
              1
            )
            .to(
              numbering,
              {
                duration: 1,
                color: 'rgb(' + cswThemePrimaryContrastColorRgb + ')',
              },
              1
            )
            .to(
              checkmarkSinglePath,
              {
                duration: 1,
                morphSVG: {
                  shape: checkmarkDoublePath,
                },
                color: 'rgb(' + cswThemePrimaryAccentColorRgb + ')',
              },
              1
            )
        )

        const body = this.$refs.body[index]
        const bodyLinePath = this.$refs.bodyLinePath[index]

        if (bodyLinePath) {
          timelines.push(
            gsap
              .timeline({
                scrollTrigger: {
                  trigger: body,
                  start: 'top top+=30%',
                  end: 'bottom top+=30%',
                  scrub: true,
                },
              })
              .fromTo(bodyLinePath, { drawSVG: '0%' }, { drawSVG: '100%' })
          )
        }
      })
    },
  },
}
