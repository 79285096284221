
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollToPlugin } from '@gsap/shockingly/dist/ScrollToPlugin'
import PrimaryNavigation from './../primary-navigation/primary-navigation.vue'

gsap.registerPlugin(ScrollToPlugin)

export default {
  name: 'Footer',

  components: {
    PrimaryNavigation,
  },

  props: {
    homeLink: {
      type: Object,
      required: false,
      default: null,
    },
    primaryNavigationLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    metaNavigationLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    relatedArticles: {
      type: Array,
      required: false,
      default: () => [],
    },
    externalServicesNavigation: {
      type: Array,
      required: false,
      default: () => [],
    },
    externalServiceAd: {
      type: Object,
      required: false,
      default: null,
    },
  },

  methods: {
    scrollToTop() {
      gsap.to(window, { duration: 1, scrollTo: 0, ease: 'power3' })
    },
  },
}
