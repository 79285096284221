
const CSW_HEADLINE_VALID_APPEARANCES = ['h1', 'h2', 'h3']
const CSW_HEADLINE_VALID_HTML_TAGS = ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']

export default {
  name: 'CswHeadline',

  props: {
    strong: {
      type: Boolean,
      required: false,
      default: false,
    },
    appearance: {
      type: String,
      required: false,
      default: 'h3',
      validator: (value) => CSW_HEADLINE_VALID_APPEARANCES.includes(value),
    },
    htmlTag: {
      type: String,
      required: false,
      default: 'h3',
      validator: (value) => CSW_HEADLINE_VALID_HTML_TAGS.includes(value),
    },
  },

  computed: {
    componentTag() {
      return CSW_HEADLINE_VALID_HTML_TAGS.includes(this.htmlTag)
        ? this.htmlTag
        : 'div'
    },
    buttonClasses() {
      return {
        'csw-headline--strong': this.strong,
        ['csw-headline--' + this.appearance]:
          CSW_HEADLINE_VALID_APPEARANCES.includes(this.appearance),
      }
    },
  },
}
