
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpShoutOutHero',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headlines() {
      const headlines = []

      if (
        Array.isArray(this.blok.headline.content) &&
        this.blok.headline.content.length &&
        Array.isArray(this.blok.headline.content[0].content)
      ) {
        this.blok.headline.content[0].content
          .filter((o) => o.type === 'text')
          .forEach((o) => {
            headlines.push(o.text)
          })
      }

      return headlines
    },

    text() {
      return this.blok.text || ''
    },
  },

  mounted() {
    document.fonts.ready.then(() => {
      this.createAnimationDesktop()
    })
  },

  methods: {
    createAnimationDesktop() {
      const svg = this.$refs.svg
      const svgHalf = this.$refs.svgHalf
      const content = this.$refs.content
      const text = this.$refs.text
      const headlineElements = this.$refs.headline
      const tlInitial = gsap.timeline({
        repeat: 0,
      })

      tlInitial
        .fromTo(
          svg,
          {
            drawSVG: '50% 50%',
          },
          {
            drawSVG: '100%',
            ease: 'none',
            duration: 3,
          },
          0.3
        )
        .fromTo(
          svgHalf,
          {
            drawSVG: '0%',
          },
          {
            drawSVG: '100%',
            ease: 'none',
            duration: 3,
          },
          0.3
        )
        .fromTo(
          headlineElements,
          {
            yPercent: 100,
            opacity: 0,
          },
          {
            yPercent: 0,
            opacity: 1,
            ease: 'power1',
            duration: 0.5,
            onComplete: () => {
              if (this.$refs.computedHeadline) {
                this.$refs.computedHeadline.startAnimation()
              }
            },
          },
          0.3
        )
        .fromTo(
          text,
          {
            yPercent: 100,
            opacity: 0,
          },
          {
            yPercent: 0,
            opacity: 1,
            ease: 'power1',
            duration: 0.5,
          },
          0.8
        )

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            markers: false,
            onEnter() {
              tlInitial.progress(100).kill()
              gsap.set([svg, svgHalf, headlineElements, text], {
                clearProps: 'all',
              })
            },
          },
        })
        .fromTo(
          svg,
          { drawSVG: '100%' },
          { drawSVG: '50% 50%', duration: 1, ease: 'none' },
          0
        )
        .fromTo(
          svgHalf,
          { drawSVG: '100%' },
          { drawSVG: '0%', duration: 1, ease: 'none' },
          0
        )
    },
  },
}
