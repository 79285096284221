
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpShyHero2',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  mounted() {
    document.fonts.ready.then(() => {
      this.createAnimationDesktop()

      this.$slots.default.forEach((vnode) => {
        vnode.componentInstance.startAnimation()
      })
    })
  },

  methods: {
    createAnimationDesktop() {
      const svg = this.$refs.svg
      const content = this.$refs.content

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            markers: false,
          },
        })
        .fromTo(
          svg,
          { y: 0, opacity: 1 },
          { y: '-100vh', opacity: 0, ease: 'none' }
        )

      const slotElements = () => {
        return this.$slots.default[0].elm.children
      }

      const tlInitial = gsap.timeline({ repeat: 0 })
      tlInitial
        .fromTo(
          svg,
          {
            drawSVG: '50% 50%',
          },
          {
            drawSVG: '100%',
            ease: 'none',
            duration: 4,
          },
          0.3
        )
        .fromTo(
          [...slotElements()].slice(1),
          {
            yPercent: 100,
            opacity: 0,
          },
          {
            yPercent: 0,
            opacity: 1,
            ease: 'power1',
            duration: 0.5,
            stagger: 0.2,
          },
          0.3
        )
    },
  },
}
