
import { gsap } from '@gsap/shockingly/dist/gsap'
import { getStoryblokLink } from './../../utils/storyblok-helper'

export default {
  name: 'CmpHeroTeaser',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },

    headlineTag() {
      return this.blok.headline_tag || 'h1'
    },

    text() {
      return this.blok.text || ''
    },

    primaryLink() {
      let content = null

      if (this.blok.primary_link_label !== '') {
        content = {
          link: getStoryblokLink(this.blok.primary_link) || '',
          icon: this.blok.primary_link_icon || '',
          label: this.blok.primary_link_label || '',
        }
      }
      return content
    },

    secondaryLink() {
      let content = null

      if (this.blok.secondary_link_label !== '') {
        content = {
          link: getStoryblokLink(this.blok.secondary_link) || '',
          icon: this.blok.secondary_link_icon || '',
          label: this.blok.secondary_link_label || '',
        }
      }
      return content
    },
  },

  mounted() {
    this.createAnimation()
  },

  methods: {
    createAnimation() {
      const headline = this.$refs.headline.$el

      const b0 =
        'linear-gradient(90deg, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(157,137,120,1) 100%)'
      const b1 =
        'linear-gradient(135deg, rgba(157,137,120,1) -40%, rgba(157,137,120,1) -40%, rgba(205,190,162,1) -25%, rgba(157,137,120,1) -10%, rgba(157,137,120,1) 120%)'
      const b2 =
        'linear-gradient(135deg, rgba(157,137,120,1) -40%, rgba(157,137,120,1) 90%, rgba(205,190,162,1) 105%, rgba(157,137,120,1) 120%, rgba(157,137,120,1) 160%)'

      gsap
        .timeline({
          repeat: 0,
        })
        .set(headline, { css: { '--gradient': b0 } })

      this.tlGradient = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 5,
        })
        .fromTo(
          headline,
          { '--gradient': b0 },
          {
            '--gradient': b2,
            duration: 1.2,
            ease: 'linear',
          },
          0
        )
        .fromTo(
          headline,
          { '--gradient': b1 },
          {
            '--gradient': b2,
            duration: 1,
            ease: 'power1',
          }
        )
        .to(headline, { '--gradient': b0, duration: 1 })
        .pause()
    },

    startAnimation() {
      this.tlGradient.play()
    },
  },
}
