export const theme = (state) => {
  return state.theme
}

export const consent = (state) => {
  return state.consent
}

export default {
  theme,
  consent,
}
