export const setTheme = (state, theme) => {
  state.theme = theme
}

export const setConsent = (state, consent) => {
  state.consent = consent
}

export default {
  setTheme,
  setConsent,
}
