export const setIsAllowedToLoad = (state, isAllowedToLoad) => {
  state.isAllowedToLoad = isAllowedToLoad
}

export const setHasConversations = (state, hasConversations) => {
  state.hasConversations = hasConversations
}

export default {
  setIsAllowedToLoad,
  setHasConversations,
}
