export const hideNotification = (state, notification) => {
  state.hidden.push(notification)
}

export const deleteNotifications = (state, notifications) => {
  const notificationIds = notifications.map((d) => d.id)
  state.hidden = state.hidden.filter((d) => !notificationIds.includes(d.id))
}

export default {
  hideNotification,
  deleteNotifications,
}
