
import { getImageSrcset, getImageSrc } from '@/utils/storyblok-helper.js'

export default {
  name: 'CswImage',

  props: {
    srcLandscape: {
      type: String,
      required: false,
      default: null,
    },
    srcPortrait: {
      type: String,
      required: false,
      default: null,
    },
    landscapeResolutions: {
      type: Array,
      required: false,
      default: () => [375, 640, 768, 1024, 1280, 1440, 2560],
    },
    portraitResolutions: {
      type: Array,
      required: false,
      default: () => [375, 640, 768, 1024, 1280, 1440, 2560],
    },
    defaultResolution: {
      type: Number,
      required: false,
      default: 1024,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    objectFit: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    pictureSources() {
      const sources = []

      if (this.srcLandscape !== null) {
        const isAdvancedSetting =
          this.landscapeResolutions.length &&
          typeof this.landscapeResolutions[0] === 'object'

        if (isAdvancedSetting) {
          this.landscapeResolutions.forEach((res, index) => {
            sources.unshift({
              srcset: getImageSrcset(this.srcLandscape, { width: res.width }),
              media: res.query,
            })
          })
        } else {
          this.landscapeResolutions.forEach((res, index) => {
            sources.unshift({
              srcset: getImageSrcset(this.srcLandscape, {
                width:
                  this.landscapeResolutions.length === index + 1
                    ? res
                    : res - 1,
              }),
              media:
                '(min-width: ' +
                (index === 0 ? 0 : this.landscapeResolutions[index - 1]) +
                'px)',
            })
          })
        }
      }

      if (this.srcPortrait !== null) {
        this.portraitResolutions.forEach((res, index) => {
          sources.unshift({
            srcset: getImageSrcset(this.srcPortrait, {
              width:
                this.portraitResolutions.length === index + 1 ? res : res - 1,
            }),
            media:
              '(min-width: ' +
              (index === 0 ? 0 : this.portraitResolutions[index - 1]) +
              'px) and (orientation: portrait)',
          })
        })
      }

      return sources
    },
    defaultPictureSource() {
      if (this.srcLandscape !== null) {
        return getImageSrc(this.srcLandscape, {
          width: this.defaultResolution - 1,
        })
      }

      return null
    },
  },
}
