
import { gsap } from '@gsap/shockingly/dist/gsap'

export default {
  props: {
    headlineLines: {
      type: Array,
      required: false,
      default: () => [],
    },
    useSmallestCommonFontSize: {
      type: Boolean,
      required: false,
      default: true,
    },
    tag: {
      type: String,
      required: false,
      default: 'h2',
      validator: (value) => {
        return ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
      },
    },
    maxFontSize: {
      type: [Number, Object],
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isCalculating: false,
      isCalculated: false,
      lastSavedWindowWidth: 0,
      tlGradient: null,
      breakpoints: [
        {
          key: '2xs',
          mediaQuery: '(min-width: 375px)',
          state: null,
        },
        {
          key: 'xs',
          mediaQuery: '(min-width: 425px)',
          state: null,
        },
        {
          key: 'sm',
          mediaQuery: '(min-width: 640px)',
          state: null,
        },
        {
          key: 'md',
          mediaQuery: '(min-width: 768px)',
          state: null,
        },
        {
          key: 'lg',
          mediaQuery: '(min-width: 1024px)',
          state: null,
        },
        {
          key: 'xl',
          mediaQuery: '(min-width: 1280px)',
          state: null,
        },
        {
          key: '2xl',
          mediaQuery: '(min-width: 1440px)',
          state: null,
        },
        {
          key: '3xl',
          mediaQuery: '(min-width: 1920px)',
          state: null,
        },
      ],
      registeredMediaQueries: [],
    }
  },

  computed: {
    classes() {
      return {
        'computed-headline--calculating': this.isCalculating,
        'computed-headline--calculated': this.isCalculated,
      }
    },

    htmlTag() {
      return ['div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(this.tag)
        ? this.tag
        : 'div'
    },

    computedMaxFontSize() {
      let maxFontSize = 64
      if (typeof this.maxFontSize === 'number') {
        maxFontSize = this.maxFontSize
      } else if (typeof this.maxFontSize === 'object') {
        maxFontSize = this.maxFontSize[this.activeBreakpoint]
      }

      return maxFontSize
    },

    activeBreakpoint() {
      const found = Array.from(this.breakpoints)
        .reverse()
        .find((d) => d.state === true)
      return found ? found.key : 'default'
    },
  },

  watch: {
    headlines() {
      this.calculateFontSizes()
    },

    headlineLines() {
      this.calculateFontSizes()
    },

    computedMaxFontSize() {
      this.calculateFontSizes()
    },

    isCalculating() {
      this.$emit('calculating', this.isCalculating)
    },
  },

  mounted() {
    this.lastSavedWindowWidth = window.innerWidth
    // TODO: maybe add here a debounce function if run into performance issues
    window.addEventListener('resize', this.onResize)
    this.initBreakpointObserver()
    this.calculateFontSizes()

    this.createAnimation()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.registeredMediaQueries.forEach((obj) => {
      obj.mediaQuery.removeEventListener('change', obj.callback)
    })
    this.registeredMediaQueries = []
  },

  methods: {
    initBreakpointObserver() {
      if (typeof this.maxFontSize === 'object') {
        const handleBreakpointChanged = (breakpoint) => {
          return (mql) => {
            const foundIndex = this.breakpoints.findIndex(
              (d) => d.key === breakpoint
            )
            if (foundIndex > -1) {
              const obj = this.breakpoints[foundIndex]

              this.$set(this.breakpoints, foundIndex, {
                ...obj,
                ...{ state: mql.matches },
              })
            }
          }
        }

        Object.keys(this.maxFontSize).forEach((breakpoint) => {
          const foundBreakpoint = this.breakpoints.find(
            (d) => d.key === breakpoint
          )
          if (foundBreakpoint !== undefined) {
            const mediaQuery = window.matchMedia(foundBreakpoint.mediaQuery)
            const callback = handleBreakpointChanged(breakpoint)
            callback(mediaQuery)
            mediaQuery.addEventListener('change', callback)
            this.registeredMediaQueries.push({ mediaQuery, callback })
          }
        })
      }
    },
    createAnimation() {
      const headline = this.$refs.computedHeadline

      const b0 =
        'linear-gradient(90deg, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(205,190,162,1) 0%, rgba(157,137,120,1) 100%)'
      const b1 =
        'linear-gradient(135deg, rgba(157,137,120,1) -40%, rgba(157,137,120,1) -40%, rgba(205,190,162,1) -25%, rgba(157,137,120,1) -10%, rgba(157,137,120,1) 120%)'
      const b2 =
        'linear-gradient(135deg, rgba(157,137,120,1) -40%, rgba(157,137,120,1) 90%, rgba(205,190,162,1) 105%, rgba(157,137,120,1) 120%, rgba(157,137,120,1) 160%)'

      gsap
        .timeline({
          repeat: 0,
        })
        .set(headline, { css: { '--gradient': b0 } })

      this.tlGradient = gsap
        .timeline({
          repeat: -1,
          repeatDelay: 5,
        })
        .fromTo(
          headline,
          { '--gradient': b0 },
          {
            '--gradient': b2,
            duration: 1.2,
            ease: 'linear',
          },
          0
        )
        .fromTo(
          headline,
          { '--gradient': b1 },
          {
            '--gradient': b2,
            duration: 1,
            ease: 'power1',
          }
        )
        .to(headline, { '--gradient': b0, duration: 1 })
        .pause()
    },

    startAnimation() {
      this.tlGradient.play()
    },

    stopAnimation() {
      this.tlGradient.progress(0).pause()
    },

    calculateFontSizes() {
      return document.fonts.ready.then(async () => {
        if (
          Array.isArray(this.$refs.headline) &&
          this.isCalculating === false
        ) {
          this.isCalculating = true
          const fontSizes = await Promise.all(
            this.$refs.headline.map(async (headline) => {
              const fontSize = await this.getFontSize(headline)
              return fontSize
            })
          )

          if (this.useSmallestCommonFontSize) {
            const minFontSize = Math.min(...fontSizes)
            this.$refs.headline.forEach((headlineWrapper) => {
              const headline = headlineWrapper.querySelector('span')
              headline.style.fontSize = minFontSize + 'px'
            })
          }
          this.isCalculating = false
          this.isCalculated = true
        }
        return Promise.resolve()
      })
    },
    async getFontSize(headlineWrapper, fontSize = 0, gap = 10) {
      const headlineWrapperWidth = headlineWrapper.offsetWidth
      const headline = headlineWrapper.querySelector('span')

      headline.style.fontSize = fontSize + 'px'

      await this.$nextTick()

      if (
        headline.offsetWidth >= headlineWrapperWidth ||
        (this.computedMaxFontSize !== null &&
          fontSize >= this.computedMaxFontSize)
      ) {
        if (gap === 1) {
          const finalFontSize =
            headline.offsetWidth > headlineWrapperWidth
              ? fontSize - 1
              : fontSize
          headline.style.fontSize = finalFontSize + 'px'

          return finalFontSize
        } else {
          return this.getFontSize(headlineWrapper, fontSize - gap, 1)
        }
      } else {
        return this.getFontSize(headlineWrapper, fontSize + gap, gap)
      }
    },
    onResize() {
      if (window.innerWidth !== this.lastSavedWindowWidth) {
        this.lastSavedWindowWidth = window.innerWidth
        this.calculateFontSizes()
      }
    },
  },
}
