
export default {
  name: 'CmpSection',

  props: {
    blok: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    classes() {
      return {
        'section--no-spacing': this.blok.gap_between_components === 'none',
      }
    },
  },
}
