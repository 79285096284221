
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollToPlugin } from '@gsap/shockingly/dist/ScrollToPlugin'
import { getAssetUrl, getStoryblokLink } from './../../utils/storyblok-helper'

gsap.registerPlugin(ScrollToPlugin)

export default {
  name: 'CmpProductStage',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      destroyTimeline: null,
    }
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },

    imageSources() {
      let sources = []

      if (this.blok.image_source_landscape.filename) {
        const getResolvedAssetUrl = (width, height = null) => {
          height = height || Math.round((9 / 16) * width)

          return getAssetUrl(
            this.blok.image_source_landscape.filename,
            width + 'x' + height + '/filters:quality(80)'
          )
        }

        // TODO: maybe also add different qualities here
        sources = [
          {
            media: '(max-width: 374px)',
            srcset: getResolvedAssetUrl(374),
          },
          {
            media: '(min-width: 375px)',
            srcset: getResolvedAssetUrl(424),
          },
          {
            media: '(min-width: 425px)',
            srcset: getResolvedAssetUrl(767),
          },
          {
            media: '(min-width: 768px)',
            srcset: getResolvedAssetUrl(1023),
          },
          {
            media: '(min-width: 1024px)',
            srcset: getResolvedAssetUrl(1279),
          },
          {
            media: '(min-width: 1280px)',
            srcset: getResolvedAssetUrl(1439),
          },
          {
            media: '(min-width: 1440px)',
            srcset: getResolvedAssetUrl(1920),
          },
        ]
      }
      return sources.reverse()
    },

    imageSourceAlt() {
      return this.blok.image_source_landscape.alt || ''
    },

    link() {
      return getStoryblokLink(this.blok.link)
    },

    linkLabel() {
      return this.blok.link_label || ''
    },

    classNames() {
      return {
        'product-stage--right': this.blok.content_alignment === 'right',
      }
    },
  },

  mounted() {
    const mql = window.matchMedia('(min-width: 768px)')
    mql.addEventListener('change', (e) =>
      this.handleAnimationDecision(e.matches)
    )
    this.handleAnimationDecision(mql.matches)
  },

  methods: {
    handleAnimationDecision(matches) {
      this.destroyTimeline && this.destroyTimeline()

      if (matches) {
        this.destroyTimeline = this.createTimeline()
      }
    },

    createTimeline() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: this.$el,
          scrub: true,
          start: 'top bottom+=10%',
          end: 'bottom top-=10%',
          markers: false,
        },
      })

      tl.fromTo(
        this.$refs.image,
        {
          y: this.$el.offsetHeight / 4,
        },
        {
          y: -this.$el.offsetHeight / 15,
          ease: 'none',
          duration: 10,
        },
        3
      )

      tl.fromTo(
        this.$refs.teaser,
        {
          y: this.$el.offsetHeight / 2,
        },
        {
          y: -this.$el.offsetHeight / 4,
          ease: 'ease-in',
          duration: 13,
        },
        0
      )

      return () => {
        tl.progress(0).kill()
        gsap.set(
          [this.$el, this.$refs.teaser].concat(this.$refs.teaser.children),
          {
            clearProps: 'all',
          }
        )
      }
    },
  },
}
