
import VRuntimeTemplate from 'v-runtime-template'
import { useStoryblokApi } from '@storyblok/nuxt'

export default {
  name: 'Richtext',

  components: {
    VRuntimeTemplate,
  },
  props: {
    html: {
      type: String,
      required: false,
      default: null,
    },
    data: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    template() {
      const storyblokApi = useStoryblokApi()
      let html = null

      if (this.html !== null) {
        html = this.html
      } else if (this.data !== null) {
        storyblokApi.setComponentResolver((component, blok) => {
          return `<component :blok='${JSON.stringify(blok)}'
                          is="${component}"></component>`
        })

        html = storyblokApi.richTextResolver.render(this.data)
      }

      return '<div class="richtext">' + html + '</div>'
    },
  },

  mounted() {
    this.$el.addEventListener('click', this.onClick)
  },

  beforeDestroy() {
    this.$el.removeEventListener('click', this.onClick)
  },

  methods: {
    onClick(e) {
      if (e.target) {
        const isAnchorTag =
          typeof e.target.tagName === 'string' &&
          e.target.tagName.toLowerCase() === 'a'
        const isInternalLink = ['', '_self'].includes(
          (e.target?.target || '').toLowerCase()
        )
        const path =
          typeof e.target.pathname === 'string' && e.target.pathname.length
            ? e.target.pathname
            : null

        if (isAnchorTag && isInternalLink && path !== null) {
          e.preventDefault()
          e.stopImmediatePropagation()
          this.$router.push({ path })
        }
      }
    },
  },
}
