export const setTheme = ({ commit }, theme) => {
  commit('setTheme', theme)
}

export const setConsent = ({ commit }, consent) => {
  commit('setConsent', consent)
}

export default {
  setTheme,
  setConsent,
}
