
import { getAssetUrl } from './../../utils/storyblok-helper'

export default {
  name: 'BlogAuthorItem',

  props: {
    firstname: {
      type: String,
      required: true,
    },
    lastname: {
      type: String,
      required: true,
    },
    avatar: {
      type: Object,
      required: false,
      default: null,
    },
  },

  computed: {
    avatarSource() {
      let src = null

      if (
        typeof this.avatar.filename === 'string' &&
        this.avatar.filename.length > 0
      ) {
        src = getAssetUrl(this.avatar.filename, 48 + 'x' + 48)
      }

      return src
    },
    avatarAlt() {
      return this.avatar?.alt || null
    },
  },
}
