
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'

gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpAccordion',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      items: [],
    }
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },
    description() {
      return this.blok.description || ''
    },
  },

  watch: {
    'blok.items': {
      handler() {
        this.setItems()
      },
      deep: true,
    },
  },

  created() {
    this.setItems()
  },

  mounted() {
    this.gsapMatchMedia = gsap.matchMedia()

    this.gsapMatchMedia.add('(min-width: 1280px)', (context) => {
      const category = this.$refs.category

      this.scrollTrigger = ScrollTrigger.create({
        trigger: this.$el,
        start: 'top-=32px top',
        end: () => {
          return 'bottom top+=' + category.offsetHeight
        },
        pin: category,
        pinSpacing: false,
        scrub: false,
      })
    })
  },

  methods: {
    setItems() {
      this.items = this.blok.items.map((item, index) => {
        return {
          id: index,
          headline: item.headline,
          text: item.text,
          isOpen: false,
        }
      })
    },
    toggleItemById(itemId) {
      const index = this.items.findIndex((d) => d.id === itemId)

      if (index !== -1) {
        const itemObject = this.items.find((d) => d.id === itemId)
        const accordionElement = this.$refs.items[index]
        const contentElement = accordionElement.querySelector(
          '.accordion__item-content'
        )

        const tl = gsap.timeline()
        tl.fromTo(contentElement, { height: 0 }, { height: 'auto' }, 0)

        if (itemObject.isOpen) {
          itemObject.isOpen = false
          tl.reverse(0).then(() => {
            ScrollTrigger.refresh()
          })
        } else {
          itemObject.isOpen = true

          tl.play().then(() => {
            ScrollTrigger.refresh()
            let scrollTo

            if (window.innerHeight < accordionElement.offsetHeight) {
              scrollTo = accordionElement.offsetTop
            } else {
              const gap =
                accordionElement.getBoundingClientRect().y +
                accordionElement.offsetHeight -
                window.innerHeight

              if (gap > 0) {
                scrollTo =
                  accordionElement.offsetTop -
                  accordionElement.getBoundingClientRect().y +
                  gap
              }
            }

            if (scrollTo !== undefined) {
              gsap.timeline().to(
                window,
                {
                  scrollTo,
                },
                0
              )
            }
          })
        }
      }
    },
  },
}
