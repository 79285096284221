
export default {
  name: 'CswChip',

  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    componentTag() {
      return this.href === null ? 'div' : this.isExternalLink ? 'a' : 'NuxtLink'
    },
    isExternalLink() {
      return (
        this.href !== null &&
        typeof this.target === 'string' &&
        this.target.toLowerCase() === '_blank'
      )
    },
    externalLinkProps() {
      return {
        href: this.href,
        target: this.target,
        rel: 'nofollow noopener',
      }
    },
    internalLinkProps() {
      return {
        to: this.href,
      }
    },
  },
}
