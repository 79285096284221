
import { useStoryblokApi } from '@storyblok/nuxt'
import { getAssetUrl } from './../../utils/storyblok-helper'

export default {
  name: 'CmpImage',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sourceSet() {
      const imageSize = [375, 640, 750, 828, 1080, 1200, 1920, 2048, 3840]
      return imageSize
        .map(
          (size) =>
            getAssetUrl(
              this.blok.source_landscape.filename,
              size + `x` + Math.round(size * 0.5625)
            ) +
            ' ' +
            size +
            'w'
        )
        .join(', ')
    },
    sizes() {
      if (this.size === 'm') {
        return '(min-width: 2176px) 1275px, (min-width: 1440px) calc((100vw - (2 * 128px)) * 0.67), (min-width: 1280px) calc((100vw - (2 * 64px)) * 0.67), (min-width: 768px) calc((100vw - (2 * 32px)) * 0.67), (min-width: 640px) calc((100vw - (2 * 16px)) * 0.67), calc(100vw - 2 * 16px)'
      } else if (this.size === 'l') {
        return '(min-width: 2176px) 1920px, (min-width: 1440px) calc(100vw - (2 * 128px)), (min-width: 1280px) calc(100vw - (2 * 64px)), (min-width: 768px) calc(100vw - (2 * 32px)), calc(100vw - (2 * 16px))'
      } else {
        return '(min-width: 2176px) 2176px, 100vw'
      }
    },
    classes() {
      return ['image', 'image--size-' + this.size]
    },
    size() {
      const validSizes = ['xl', 'l', 'm']
      return validSizes.includes(this.blok.size)
        ? this.blok.size
        : validSizes[0]
    },
    caption() {
      const storyblokApi = useStoryblokApi()
      let caption = this.blok.source_landscape?.title || null

      const isEmptyCaption = (caption) => {
        return (
          !Array.isArray(caption?.content) ||
          (caption.content.length === 1 &&
            caption.content[0].content === undefined)
        )
      }

      if (!isEmptyCaption(this.blok.caption)) {
        caption = storyblokApi.richTextResolver.render(this.blok.caption)
      }

      return caption
    },
  },
}
