import { useStoryblokApi } from '@storyblok/nuxt'

export const getRelatedArticles = async (
  articleUUIDs,
  categoryUUIDs,
  tagUUIDs,
  excludeArticleIds,
  populateWithRelatedArticles,
  localeConfig,
  maxArticleCount,
  storyblokContentVersion,
  storyblokCacheVersion
) => {
  let relatedArticles = []

  const storyblokApi = useStoryblokApi()

  if (articleUUIDs.length) {
    relatedArticles = relatedArticles.concat(
      await storyblokApi
        .get(`cdn/stories`, {
          version: storyblokContentVersion,
          cv: storyblokCacheVersion,
          resolve_links: 'url',
          content_type: 'blog-article',
          starts_with: localeConfig.slug,
          by_uuids_ordered: articleUUIDs.join(','),
          per_page: maxArticleCount,
          page: 1,
        })
        .then((res) => {
          return res.data.stories
        })
        .catch(() => {
          return []
        })
    )
  }

  if (populateWithRelatedArticles && relatedArticles.length < maxArticleCount) {
    // automation
    const filterQuery = {}

    if (categoryUUIDs.length) {
      filterQuery.category = {
        any_in_array: categoryUUIDs.join(','),
      }
    }

    if (tagUUIDs.length) {
      filterQuery.tags = {
        any_in_array: tagUUIDs.join(','),
      }
    }

    let excludingIds = []

    if (relatedArticles.length) {
      excludingIds = excludingIds.concat(
        relatedArticles.map((a) => a.id).join(',')
      )
    }

    if (excludeArticleIds.length) {
      excludingIds = excludingIds.concat(excludeArticleIds)
    }

    relatedArticles = relatedArticles.concat(
      await storyblokApi
        .get(`cdn/stories`, {
          version: storyblokContentVersion,
          cv: storyblokCacheVersion,
          resolve_links: 'url',
          content_type: 'blog-article',
          starts_with: localeConfig.slug,
          filter_query: filterQuery,
          excluding_ids: excludingIds.join(','),
          sort_by: 'content.featured:desc,content.creation_date:desc',
          per_page: maxArticleCount - relatedArticles.length,
          page: 1,
        })
        .then((res) => {
          return res.data.stories
        })
        .catch(() => {
          return []
        })
    )
  }

  return relatedArticles
}

export default {
  getRelatedArticles,
}
