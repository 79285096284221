class Ga4Tracker {
  static scriptUrl =
    'https://www.googletagmanager.com/gtag/js?id=<<measurementId>>'

  #measurementId = null
  #enabled = false
  #dataLayerCache = []

  init(measurementId) {
    window.dataLayer = window.dataLayer || []

    this.push({
      event: 'gtm.js',
      'gtm.start': new Date().getTime(),
    })

    this.push(['js', new Date()])
    this.push(['config', measurementId])

    this.#measurementId = measurementId

    return this
  }

  push(trackObj) {
    window.dataLayer.push(trackObj)
  }

  getScriptUrl() {
    let url = null

    if (this.#measurementId) {
      url = Ga4Tracker.scriptUrl.replace(
        '<<measurementId>>',
        this.#measurementId
      )
    }

    return url
  }

  cache(trackObject) {
    this.#dataLayerCache.push(trackObject)
  }

  flush() {
    if (this.#enabled) {
      this.#dataLayerCache.forEach((trackObject) => {
        this.push(trackObject)
      })
      this.#dataLayerCache = []
    }
  }

  enable() {
    window['ga-disable-' + this.#measurementId] = false
    this.#enabled = true
    this.flush()
  }

  disable() {
    window['ga-disable-' + this.#measurementId] = true
    this.#enabled = false
  }

  trackEvent(event) {
    const trackObject = Object.assign({}, { event: event.name }, event.payload)
    const trackMethod = this.#enabled ? this.push : this.cache.bind(this)

    trackMethod(trackObject)
  }
}

export const Tracking = (() => {
  let instance

  return {
    getInstance() {
      if (!instance) {
        instance = new Ga4Tracker()
      }
      return instance
    },
  }
})()
