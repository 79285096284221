
export default {
  name: 'CswMenuLink',

  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    exact: {
      type: Boolean,
      required: false,
      default: false,
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    iconComponentName() {
      let name = null

      if (this.icon) {
        name = 'icon-' + this.icon
      }

      return name
    },
  },
}
