
export default {
  name: 'LinkResolver',

  props: {
    isExternal: {
      type: Boolean,
      required: false,
      default: true,
    },
    noFollow: {
      type: Boolean,
      required: false,
      default: true,
    },
    href: {
      type: String,
      required: false,
      default: '',
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
  },

  computed: {
    externalLinkProps() {
      return {
        href: this.href,
        target: this.target,
        rel: 'noopener' + (this.noFollow ? ' nofollow' : ''),
      }
    },
    internalLinkProps() {
      return {
        to: this.href || '',
        target: this.target,
      }
    },
    componentTag() {
      return this.isExternal ? 'a' : 'NuxtLink'
    },
  },
}
