
import { getStoryblokLink } from './../../utils/storyblok-helper'

export default {
  name: 'CmpSourceReference',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    sourceReferences() {
      return this.blok.items.map((item) => ({
        symbol: item.symbol,
        text: item.text,
        link: Object.assign(getStoryblokLink(item.link), {
          label: item.link_label,
        }),
      }))
    },
  },
}
