export const isAllowedToLoad = (state) => {
  return state.isAllowedToLoad
}

export const hasConversations = (state) => {
  return state.hasConversations
}

export default {
  isAllowedToLoad,
  hasConversations,
}
