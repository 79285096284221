
import { useStoryblokApi } from '@storyblok/nuxt'
import { parseISO, format } from 'date-fns'
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getSanitizedFullSlug } from './../../utils/url-helper'
import { getAssetUrl } from './../../utils/storyblok-helper'
gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'BlogCategory',

  props: {
    blok: {
      type: Object,
      required: true,
    },
    rootStory: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      featuredArticles: [],
      articles: [],
      scrollTrigger: null,
      tlHeadlineOpacity: null,
      tlHeadlineOpacityProgress: 0,
    }
  },
  async fetch() {
    const storyblokApi = useStoryblokApi()

    // featured article
    if (this.blok.featured_articles.length) {
      this.featuredArticles = await storyblokApi
        .get(`cdn/stories`, {
          version: this.$nuxt.context.env.CONTENT_VERSION,
          cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
          resolve_links: 'url',
          by_uuids_ordered: this.blok.featured_articles.join(','),
        })
        .then((res) => {
          return res.data.stories
            .map((story) => {
              let imageLandscape = story.content.image_landscape
              if (
                typeof story.content.image_landscape.filename !== 'string' ||
                story.content.image_landscape.filename.length === 0
              ) {
                imageLandscape = this.blok.default_article_image_landscape
              }
              return Object.assign({}, story, {
                content: Object.assign({}, story.content, {
                  image_landscape: imageLandscape,
                }),
              })
            })
            .filter((story) => {
              return (
                typeof story.content.image_landscape.filename === 'string' &&
                story.content.image_landscape.filename.length > 0
              )
            })
            .map((story, index) => {
              return {
                headline: story.content.title,
                text: story.content.subline,
                date: format(
                  parseISO(story.content.creation_date),
                  'yyyy-MM-dd'
                ),
                formattedDate: format(
                  parseISO(story.content.creation_date),
                  'dd.MM.yyyy'
                ),
                category: this.blok.title,
                href: getSanitizedFullSlug(story.full_slug),
                imageSources: this.getImageSources(
                  story.content.image_landscape
                ),
                imagePosition: index % 2 === 0 ? 'left' : 'right',
                imageAlt: story.content.image_landscape.alt,
              }
            })
        })
    }

    // fetch further articles but exclude already fetched featured articles
    // set the total amount of the articles of the page
    const totalArticles = 35

    this.articles = await storyblokApi
      .get(`cdn/stories`, {
        version: this.$nuxt.context.env.CONTENT_VERSION,
        cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
        resolve_links: 'url',
        content_type: 'blog-article',
        excluding_ids: this.blok.featured_articles.map((a) => a.id).join(','),
        filter_query: {
          category: {
            all_in_array: this.rootStory.uuid,
          },
        },
        per_page: totalArticles,
        page: 1,
        sort_by: 'content.creation_date:desc',
      })
      .then((res) => {
        return res.data.stories.map((article) => {
          return {
            headline: article.content.title,
            text: article.content.subline,
            date: format(parseISO(article.content.creation_date), 'yyyy-MM-dd'),
            formattedDate: format(
              parseISO(article.content.creation_date),
              'dd.MM.yyyy'
            ),
            category: this.blok.title,
            href: getSanitizedFullSlug(article.full_slug),
            imageSources: [],
            imagePosition: null,
          }
        })
      })
  },

  computed: {
    headlineLines() {
      return this.getHeadlineLines(this.blok.formatted_title)
    },
  },

  watch: {
    '$fetchState.pending'(isPending) {
      if (isPending === false) {
        ScrollTrigger.refresh()
      }
    },
  },

  beforeDestroy() {
    this.destroyAnimation()
  },
  mounted() {
    this.$refs.computedHeadline.startAnimation()
  },

  methods: {
    destroyAnimation() {
      if (this.scrollTrigger !== null) {
        if (this.tlHeadlineOpacity !== null) {
          this.tlHeadlineOpacityProgress = this.tlHeadlineOpacity.progress()
        }

        this.scrollTrigger.kill(true)
        this.scrollTrigger = null
      }
    },

    onCalculatingChange(isCalculating) {
      if (!isCalculating) {
        if (this.scrollTrigger === null) {
          this.createAnimation()
        }
      } else if (this.scrollTrigger !== null) {
        this.destroyAnimation()
      }
    },

    createAnimation() {
      const headline = this.$refs.computedHeadlineWrapper

      this.tlHeadlineOpacity = gsap.timeline().fromTo(
        headline,
        {
          opacity: 1,
        },
        {
          opacity: 0.1,
          duration: 0.5,
        },
        0
      )

      this.tlHeadlineOpacity.progress(this.tlHeadlineOpacityProgress)

      this.scrollTrigger = ScrollTrigger.create({
        trigger: this.$refs.blogCategory,
        start: 'top top',
        end: () => {
          return 'bottom top+=' + headline.offsetHeight
        },
        animation: this.tlHeadlineOpacity,
        pin: headline,
        pinSpacing: false,
        scrub: false,
        markers: false,
        onEnter: () => {
          this.$refs.computedHeadline.stopAnimation()
        },
        onLeaveBack: () => {
          this.tlHeadlineOpacity.reversed(true)
          this.$refs.computedHeadline.startAnimation()
        },
      })
    },

    getImageSources(imageLandscape) {
      let sources = []
      const getResolvedAssetUrl = (width, height = null) => {
        height = height || Math.round((9 / 16) * width)
        return getAssetUrl(imageLandscape.filename, width + 'x' + height)
      }

      if (imageLandscape.filename !== null) {
        sources = [
          {
            media: '(max-width: 374px)',
            srcset: getResolvedAssetUrl(342),
          },
          {
            media: '(min-width: 375px)',
            srcset: getResolvedAssetUrl(392),
          },
          {
            media: '(min-width: 425px)',
            srcset: getResolvedAssetUrl(735),
          },
          {
            media: '(min-width: 768px)',
            srcset: getResolvedAssetUrl(959),
          },
          {
            media: '(min-width: 1024px)',
            srcset: getResolvedAssetUrl(600),
          },
          {
            media: '(min-width: 1280px)',
            srcset: getResolvedAssetUrl(648),
          },
          {
            media: '(min-width: 1440px)',
            srcset: getResolvedAssetUrl(824),
          },
        ]
      }

      return sources.reverse()
    },
    getHeadlineLines(richtext) {
      const headlines = []

      if (
        Array.isArray(richtext.content) &&
        richtext.content.length &&
        Array.isArray(richtext.content[0].content)
      ) {
        richtext.content[0].content
          .filter((o) => o.type === 'text')
          .forEach((o, i) => {
            if (i < 2) {
              headlines.push(o.text)
            }
          })
      }

      return headlines
    },
  },
}
