
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollToPlugin } from '@gsap/shockingly/dist/ScrollToPlugin'
import { useStoryblokApi } from '@storyblok/nuxt'
import { parseISO, format } from 'date-fns'
import CategoryArticleTeaser from '../category-article-teaser/category-article-teaser.vue'
import BlogNavigation from '../blog-navigation/blog-navigation.vue'
import { getSanitizedFullSlug } from './../../utils/url-helper'
import { getAssetUrl } from './../../utils/storyblok-helper'

gsap.registerPlugin(ScrollToPlugin)

export default {
  components: { CategoryArticleTeaser, BlogNavigation },
  props: {
    blok: {
      type: Object,
      required: true,
    },
    rootStory: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    localeConfig: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
    blogCategories: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      verticalScrollAnimation: null,
      useScrollObserver: true,
      selectedCategoryId: null,
      intersectionObserverInstance: null,
      navigationSectionElements: [],
      intersectionObserverReturnValue: [],
      navigationLinks: [],
      categoryTeasers: [],
      featuredArticles: [],
      furtherCategories: [],
    }
  },
  async fetch() {
    let resolvedCategories = []
    const storyblokApi = useStoryblokApi()

    if (this.blogCategories.length === 0) {
      const getBlogCategories = (categories = [], page = 1) => {
        const perPage = 25
        return storyblokApi
          .get(`cdn/stories`, {
            version: this.$nuxt.context.env.CONTENT_VERSION,
            cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
            resolve_links: 'url',
            starts_with: this.localeConfig.slug,
            content_type: 'blog-category',
            per_page: perPage,
            page,
          })
          .then(async (res) => {
            const total = parseInt(res.headers.total)

            const resolvedCategories = await Promise.all(
              res.data.stories.map(async (story) => {
                if (story.content.featured_articles.length) {
                  const featuredArticles = await storyblokApi
                    .get(`cdn/stories`, {
                      version: this.$nuxt.context.env.CONTENT_VERSION,
                      cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
                      resolve_links: 'url',
                      by_uuids_ordered:
                        story.content.featured_articles.join(','),
                    })
                    .then((res) => {
                      return res.data.stories
                    })

                  return Object.assign({}, story, {
                    content: Object.assign({}, story.content, {
                      featured_articles: featuredArticles,
                    }),
                  })
                } else {
                  return story
                }
              })
            )

            categories = categories.concat(resolvedCategories)

            if (total > perPage * page) {
              return getBlogCategories(categories, ++page)
            } else {
              return categories
            }
          })
      }

      const categories = await getBlogCategories()

      resolvedCategories = categories
    } else {
      resolvedCategories = this.blogCategories
    }

    // featured articles
    const resolvedFeaturedArticles = await storyblokApi
      .get(`cdn/stories`, {
        version: this.$nuxt.context.env.CONTENT_VERSION,
        cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
        resolve_links: 'url',
        by_uuids_ordered: this.blok.featured_articles.join(','),
      })
      .then((res) => {
        return res.data.stories
          .map((story) => {
            let imageLandscape = story.content.image_landscape
            if (story.content.image_landscape.filename === null) {
              const foundCategory = resolvedCategories.find((category) => {
                return category.uuid === story.content.category[0]
              })
              if (foundCategory !== undefined) {
                imageLandscape =
                  foundCategory.content.default_article_image_landscape
              }
            }
            return Object.assign({}, story, {
              content: Object.assign({}, story.content, {
                image_landscape: imageLandscape,
              }),
            })
          })
          .filter((story) => {
            return (
              typeof story.content.image_landscape.filename === 'string' &&
              story.content.image_landscape.filename.length > 0
            )
          })
      })

    this.featuredArticles = resolvedFeaturedArticles.map((story, index) => {
      const category = resolvedCategories.find((category) => {
        return category.uuid === story.content.category[0]
      })

      return {
        headline: story.content.title,
        text: story.content.subline,
        date: format(parseISO(story.content.creation_date), 'yyyy-MM-dd'),
        formattedDate: format(
          parseISO(story.content.creation_date),
          'dd.MM.yyyy'
        ),
        category: category.name,
        href: getSanitizedFullSlug(story.full_slug),
        imageSources: this.getImageSources(story.content.image_landscape) || [],
        imageAlt: story.content.image_landscape.alt,
        imagePosition: index % 2 ? 'right' : 'left',
      }
    })

    // featured categories
    const featuredCategories = this.blok.featured_categories
      .map((uuid) => {
        return resolvedCategories.find((rc) => rc.uuid === uuid)
      })
      .filter((c) => c !== undefined)

    this.categoryTeasers = await Promise.all(
      featuredCategories.map(async (category, cIndex) => {
        let furtherArticles = []
        const featuredCategoryFeaturedArticles =
          category.content.featured_articles
        const totalArticles = 4

        // check how much further non-featured articles we need to fetch
        const countOfArticlesToFetch =
          totalArticles - featuredCategoryFeaturedArticles.length

        if (countOfArticlesToFetch > 0) {
          // fetch further articles but exclude already fetched featured articles
          furtherArticles = await storyblokApi
            .get(`cdn/stories`, {
              version: this.$nuxt.context.env.CONTENT_VERSION,
              cv: this.$nuxt.context.env.CONTENT_CACHE_VERSION,
              resolve_links: 'url',
              starts_with: this.localeConfig.slug,
              content_type: 'blog-article',
              excluding_ids: featuredCategoryFeaturedArticles
                .map((a) => a.id)
                .concat(resolvedFeaturedArticles.map((a) => a.id))
                .join(','),
              filter_query: {
                category: {
                  all_in_array: category.uuid,
                },
              },
              per_page: countOfArticlesToFetch,
              page: 1,
              sort_by: 'content.creation_date:desc',
            })
            .then((res) => {
              return res.data.stories
            })
        }

        const articles =
          featuredCategoryFeaturedArticles.concat(furtherArticles)

        // if the first article has an image, reduce the total images to 4
        if (articles.length === 4) {
          const hasImage =
            typeof articles[0].content.image_landscape.filename === 'string' &&
            articles[0].content.image_landscape.filename.length > 0

          if (hasImage) {
            articles.pop()
          }
        }

        return {
          id: 'section-' + category.uuid,
          articles: articles.map((article, index) => {
            return {
              headline: article.content.title,
              text: article.content.subline,
              date: format(
                parseISO(article.content.creation_date),
                'yyyy-MM-dd'
              ),
              formattedDate: format(
                parseISO(article.content.creation_date),
                'dd.MM.yyyy'
              ),
              category: category.content.title,
              href: getSanitizedFullSlug(article.full_slug),
              imageLazyload: this.featuredArticles.length > 0 || cIndex > 0,
              imageSources:
                index === 0
                  ? this.getImageSources(article.content.image_landscape)
                  : [],
              imageAlt: article.content.image_landscape.alt,
            }
          }),
          headlineLines: this.getHeadlineLines(
            category.content.formatted_title
          ),
          moreArticlesLinkLabel: category.content.more_articles_link_label,
          moreArticlesLinkHref: getSanitizedFullSlug(category.full_slug),
        }
      })
    )

    // further categories (none featured)
    const resolvedFurtherCategories = resolvedCategories.filter((c) => {
      return featuredCategories.find((fc) => fc.uuid === c.uuid) === undefined
    })

    this.furtherCategories = resolvedFurtherCategories.map((c) => {
      return {
        label: c.content.title,
        href: getSanitizedFullSlug(c.full_slug),
      }
    })

    // navigation
    this.navigationLinks = featuredCategories.map((d) => {
      return {
        id: 'section-' + d.uuid,
        label: d.content.title,
        href: this.$route.path,
      }
    })

    if (this.featuredArticles.length) {
      this.navigationLinks.unshift({
        id: 'section-featured-articles',
        label: this.blok.featured_articles_label,
        href: this.$route.path,
      })
    }
  },

  watch: {
    $fetchState: {
      handler(state) {
        if (state.pending === false) {
          this.init()
        }
      },
      deep: true,
    },

    useScrollObserver(shouldBeUsed) {
      if (shouldBeUsed) {
        this.startNavigationSectionObserving()
      } else {
        this.stopNavigationSectionObserving()
      }
    },
  },

  mounted() {
    if (this.$fetchState.pending === false) {
      this.init()
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    this.intersectionObserverInstance &&
      this.intersectionObserverInstance.disconnect()
  },

  methods: {
    init() {
      document.fonts.ready.then(() => {
        this.initScrollObserver()
      })
    },

    getImageSources(imageLandscape) {
      let sources = []
      const getResolvedAssetUrl = (width, height = null) => {
        height = height || Math.round((9 / 16) * width)
        return getAssetUrl(imageLandscape.filename, width + 'x' + height)
      }

      if (
        typeof imageLandscape.filename === 'string' &&
        imageLandscape.filename.length > 0
      ) {
        sources = [
          {
            media: '(max-width: 374px)',
            srcset: getResolvedAssetUrl(342),
          },
          {
            media: '(min-width: 375px)',
            srcset: getResolvedAssetUrl(392),
          },
          {
            media: '(min-width: 425px)',
            srcset: getResolvedAssetUrl(735),
          },
          {
            media: '(min-width: 768px)',
            srcset: getResolvedAssetUrl(959),
          },
          {
            media: '(min-width: 1024px)',
            srcset: getResolvedAssetUrl(600),
          },
          {
            media: '(min-width: 1280px)',
            srcset: getResolvedAssetUrl(648),
          },
          {
            media: '(min-width: 1440px)',
            srcset: getResolvedAssetUrl(824),
          },
        ]
      }

      return sources.reverse()
    },
    getHeadlineLines(richtext) {
      const headlines = []

      if (
        Array.isArray(richtext.content) &&
        richtext.content.length &&
        Array.isArray(richtext.content[0].content)
      ) {
        richtext.content[0].content
          .filter((o) => o.type === 'text')
          .forEach((o, i) => {
            if (i < 2) {
              headlines.push(o.text)
            }
          })
      }

      return headlines
    },
    initScrollObserver() {
      const options = {
        threshold: [
          0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07, 0.08, 0.09, 0.1, 0.11, 0.12,
          0.13, 0.14, 0.15, 0.16, 0.17, 0.18, 0.19, 0.2, 0.21, 0.22, 0.23, 0.24,
          0.25, 0.26, 0.27, 0.28, 0.29, 0.3, 0.31, 0.32, 0.33, 0.34, 0.35, 0.36,
          0.37, 0.38, 0.39, 0.4, 0.41, 0.42, 0.43, 0.44, 0.45, 0.46, 0.47, 0.48,
          0.49, 0.5, 0.51, 0.52, 0.53, 0.54, 0.55, 0.56, 0.57, 0.58, 0.59, 0.6,
          0.61, 0.62, 0.63, 0.64, 0.65, 0.66, 0.67, 0.68, 0.69, 0.7, 0.71, 0.72,
          0.73, 0.74, 0.75, 0.76, 0.77, 0.78, 0.79, 0.8, 0.81, 0.82, 0.83, 0.84,
          0.85, 0.86, 0.87, 0.88, 0.89, 0.9, 0.91, 0.92, 0.93, 0.94, 0.95, 0.96,
          0.97, 0.98, 0.99, 1,
        ],
      }

      if (this.$refs.featuredArticles !== undefined) {
        this.navigationSectionElements.push(this.$refs.featuredArticles)
      }

      if (this.$refs.categoryArticleTeaser !== undefined) {
        this.$refs.categoryArticleTeaser.forEach((el) =>
          this.navigationSectionElements.push(el)
        )
      }

      if (this.navigationSectionElements.length) {
        this.intersectionObserverInstance = new IntersectionObserver(
          (entries) => {
            if (this.intersectionObserverReturnValue.length === 0) {
              entries.forEach((el) => {
                this.intersectionObserverReturnValue.push(el)
              })
            }
            entries.forEach((el) => {
              if (this.intersectionObserverReturnValue.length) {
                for (
                  let i = 0;
                  i <= this.intersectionObserverReturnValue.length;
                  i++
                ) {
                  if (
                    el.target.attributes.id.nodeValue ===
                    this.intersectionObserverReturnValue[i].target.attributes.id
                      .nodeValue
                  ) {
                    this.intersectionObserverReturnValue.splice(i, 1)
                    this.intersectionObserverReturnValue.push(el)
                    break
                  }
                }
              }
            })

            const sortedEntries = this.intersectionObserverReturnValue.sort(
              (a, b) => (a.intersectionRatio > b.intersectionRatio ? -1 : 1)
            )

            if (sortedEntries.length) {
              this.selectedCategoryId =
                sortedEntries[0].target.attributes.id.nodeValue
            }

            if (this.intersectionObserverReturnValue.length) {
              // x
              let moreThanOneInViewport = 0
              const sortedEntriesRefereToDom = []

              const ids = Array.from(
                this.$refs.blogHomepageWrapper.children
              ).map((element) => {
                return element.id
              })

              ids.forEach((el) => {
                for (
                  let i = 0;
                  i < this.intersectionObserverReturnValue.length;
                  i++
                ) {
                  if (
                    el ===
                      this.intersectionObserverReturnValue[i].target.attributes
                        .id.nodeValue &&
                    this.intersectionObserverReturnValue[i].isIntersecting ===
                      true
                  ) {
                    sortedEntriesRefereToDom.push(
                      this.intersectionObserverReturnValue[i]
                    )
                  }
                }
              })

              this.intersectionObserverReturnValue.forEach((el) => {
                if (el.intersectionRatio > 0.7) {
                  moreThanOneInViewport++
                }
              })

              if (moreThanOneInViewport >= 2) {
                this.selectedCategoryId =
                  sortedEntriesRefereToDom[0].target.attributes.id.nodeValue
              }
            }
          },
          options
        )

        this.startNavigationSectionObserving()
      }
    },

    startNavigationSectionObserving() {
      this.navigationSectionElements.forEach((el) =>
        this.intersectionObserverInstance.observe(el)
      )
    },

    stopNavigationSectionObserving() {
      this.navigationSectionElements.forEach((el) =>
        this.intersectionObserverInstance.unobserve(el)
      )
    },

    scrollToSection(selectedCategoryId) {
      this.useScrollObserver = false
      if (this.verticalScrollAnimation) {
        this.verticalScrollAnimation.kill()
      }

      this.verticalScrollAnimation = gsap.to(window, {
        duration: 1.5,
        scrollTo: {
          y: '#' + selectedCategoryId,
          autoKill: true,
          offsetY: 96,
          onAutoKill: () => {
            this.useScrollObserver = true
          },
        },
        ease: 'power3',
        onComplete: () => {
          this.useScrollObserver = true
        },
      })
    },
  },
}
