
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpUspComb',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      gsapMatchMedia: null,
      scrollTrigger: null,
    }
  },

  computed: {
    headline() {
      return this.blok.headline || ''
    },
    labels() {
      let labels = []

      if (this.blok.items) {
        labels = this.blok.items.map((label) => {
          return label.text
        })
      }

      return labels
    },
  },

  beforeDestroy() {
    this.removeEventListener()
    this.removeAnimation()
  },

  mounted() {
    this.animationLogic()
  },

  methods: {
    removeAnimation() {
      this.gsapMatchMedia.revert()
      this.gsapMatchMedia = null
      this.scrollTrigger = null
    },

    removeEventListener() {
      if (this.addEventListener) {
        window.removeEventListener('theme', this.addEventListener)
      }
    },

    animationLogic() {
      document.fonts.ready.then(() => {
        this.createScrollTrigger()
        this.addEventListener = window.addEventListener('theme', () => {
          setTimeout(() => {
            if (this.scrollTrigger !== null) {
              this.gsapMatchMedia.revert()
              this.gsapMatchMedia = null
              this.scrollTrigger = null
            }

            if (this.scrollTrigger === null) {
              this.createScrollTrigger()
            }

            this.$nextTick(() => {
              ScrollTrigger.refresh(true)
            })
          }, 200) // TODO
        })
      })
    },

    createScrollTrigger() {
      const el = this.$refs.comb
      this.gsapMatchMedia = gsap.matchMedia()
      this.gsapMatchMedia.add(
        {
          isMobile: '(max-width: 1023px)',
          isDesktop: '(min-width: 1024px)',
        },
        (context) => {
          const { isMobile, isDesktop } = context.conditions

          this.scrollTrigger = ScrollTrigger.create({
            trigger: el,
            start: 'top top+=10%',
            end: 'bottom top+=10%',
            animation: isMobile
              ? this.createAnimationMobile()
              : isDesktop
              ? this.createAnimationDesktop()
              : false,
            pin: el,
            pinSpacing: true,
            scrub: true,
            markers: false,
            refreshPriority: 100,
          })
        }
      )
    },

    createAnimationDesktop() {
      const label1 = this.$refs.label[0]
      const label2 = this.$refs.label[1]
      const label3 = this.$refs.label[2]
      const label4 = this.$refs.label[3]
      const label5 = this.$refs.label[4]
      const label6 = this.$refs.label[5]
      const svg = this.$refs.svg
      const svgPath = this.$refs.svgPath
      const cswThemePrimaryColor = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-primary-color')
        .trim()
      const cswThemePrimaryColorLight = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-primary-color-light')
        .trim()

      const tlDesktop = gsap
        .timeline()
        .fromTo(
          label1,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          }
        )
        .to(
          label1,
          {
            duration: 0.7,
            scale: 1,
            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          1
        )
        .fromTo(
          svg,
          {
            '--svg-color': cswThemePrimaryColorLight,
          },
          { duration: 6, ease: 'linear' },
          1
        )
        .fromTo(
          svgPath,
          {
            drawSVG: '0%',
          },
          { duration: 6, ease: 'linear', drawSVG: '100%' },
          1
        )
        .fromTo(
          label2,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          },
          1
        )
        .to(
          label2,
          {
            duration: 0.7,
            scale: 1,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          2.1
        )
        .fromTo(
          label3,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          },
          2
        )
        .to(
          label3,
          {
            duration: 0.7,
            scale: 1,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          3.1
        )
        .fromTo(
          label4,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          },
          3
        )
        .to(
          label4,
          {
            duration: 0.7,
            scale: 1,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          4.1
        )
        .fromTo(
          label5,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          },
          4
        )
        .to(
          label5,
          {
            duration: 0.7,
            scale: 1,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          5.1
        )
        .fromTo(
          label6,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1.5,
            opacity: 1,
            y: 0,
          },
          5
        )
        .to(
          label6,
          {
            duration: 0.7,
            scale: 1,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          6.1
        )

      return tlDesktop
    },

    createAnimationMobile() {
      const label1 = this.$refs.label[0]
      const label2 = this.$refs.label[1]
      const label3 = this.$refs.label[2]
      const label4 = this.$refs.label[3]
      const label5 = this.$refs.label[4]
      const label6 = this.$refs.label[5]
      const svg = this.$refs.svg
      const svgPath = this.$refs.svgPath
      const cswThemePrimaryColor = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-primary-color')
        .trim()
      const cswThemePrimaryColorLight = getComputedStyle(this.$el)
        .getPropertyValue('--csw-theme-primary-color-light')
        .trim()

      const tlMobile = gsap
        .timeline()
        .fromTo(
          svg,
          {
            '--svg-color': cswThemePrimaryColorLight,
          },
          { duration: 6, ease: 'linear' },
          '0'
        )
        .fromTo(
          svgPath,
          {
            drawSVG: '0%',
          },
          { duration: 6, ease: 'linear', drawSVG: '100%' },
          '0'
        )
        .fromTo(
          label1,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          0
        )
        .to(
          label1,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          1.1
        )
        .fromTo(
          label2,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          1
        )
        .to(
          label2,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          2.1
        )
        .fromTo(
          label3,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          2
        )
        .to(
          label3,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          3.1
        )
        .fromTo(
          label4,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          3
        )
        .to(
          label4,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          4.1
        )
        .fromTo(
          label5,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          4
        )
        .to(
          label5,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          5.1
        )
        .fromTo(
          label6,
          {
            opacity: 0,
            color: 'rgb(' + cswThemePrimaryColorLight + ')',
            y: '+=25',
          },
          {
            duration: 1,
            ease: 'linear',
            scale: 1,
            opacity: 1,
            y: 0,
          },
          5
        )
        .to(
          label6,
          {
            duration: 0.7,
            scale: 0,

            color: 'rgb(' + cswThemePrimaryColor + ')',
          },
          6.1
        )
        .fromTo(
          svg,
          {
            '--svg-color': cswThemePrimaryColorLight + ' / 1',
          },
          {
            '--svg-color': cswThemePrimaryColorLight + ' / 0.1',
          },
          6.1
        )
        .to(
          label1,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            y: '-=90',
            x: '-=30%',
          },
          6.8
        )
        .to(
          label2,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            x: '-=30%',
          },
          6.8
        )
        .to(
          label3,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            y: '+=90',
            x: '-=30%',
          },
          6.8
        )
        .to(
          label4,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            y: '-=90',
            x: '+=30%',
          },
          6.8
        )
        .to(
          label5,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            x: '+=30%',
          },
          6.8
        )
        .to(
          label6,

          {
            duration: 1,
            ease: 'linear',
            scale: 0.6,
            y: '+=90',
            x: '+=30%',
          },
          6.8
        )
      return tlMobile
    },
  },
}
