
export default {
  name: 'CswLogo',

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    watermark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
