
import { parseISO, format } from 'date-fns'
import { useStoryblokApi } from '@storyblok/nuxt'
import {
  getStoryblokLink,
  getLocaleConfigByPath,
} from './../../utils/storyblok-helper'
import { getArticlePreview } from './../../utils/article-helper'
import { getRelatedArticles as _getRelatedArticles } from './../../requests/articles'
import PanoramaHero from '@/components/panorama-hero/panorama-hero.vue'
import BlogFacts from '@/components/blog-facts/blog-facts.vue'
import BlogFact from '@/components/blog-facts/blog-fact.vue'
import BlogAuthorItem from '@/components/blog-author-item/blog-author-item.vue'
import BlogIntro from '@/components/blog-intro/blog-intro.vue'
import HeroTeaser from '@/components/hero-teaser/hero-teaser.vue'

const getFakedRichtextObjectbyString = (str) => {
  return {
    type: 'doc',
    content: [
      {
        type: 'paragraph',
        content: [
          {
            text: str,
            type: 'text',
          },
        ],
      },
    ],
  }
}

export default {
  components: {
    PanoramaHero,
    BlogFacts,
    BlogFact,
    BlogAuthorItem,
    BlogIntro,
    HeroTeaser,
  },

  props: {
    blok: {
      type: Object,
      required: true,
    },
    rootStory: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      relatedArticles: [],
    }
  },

  async fetch() {
    this.relatedArticles = await this.getRelatedArticles()
  },

  head() {
    const meta = []

    meta.push({
      hid: 'og:type',
      property: 'og:type',
      content: 'article',
    })

    if (this.category) {
      meta.push({
        hid: 'article:section',
        property: 'article:section',
        content: this.category.content.title,
      })
    }

    if (this.blok.creation_date) {
      meta.push({
        hid: 'article:published_time',
        property: 'article:published_time',
        content: format(parseISO(this.blok.creation_date), 'yyyy-MM-dd'),
      })
    }

    if (this.author && this.author.profile_link.href !== null) {
      meta.push({
        hid: 'article:author',
        property: 'article:author',
        content: this.author.profile_link.href,
      })
    }

    /*
    https://ogp.me/

    // hid
    <meta property="og:image" content="https://example.com/rock.jpg" />
    <meta property="og:image:width" content="300" />
    <meta property="og:image:height" content="300" />
    */

    return {
      meta,
      /*
      link: [
        {
          rel: 'preload',
          as: 'image',
          href: 'https://assets.cs-webdevelopment.com/4618x3464/252c915ca9/img_20210712_165938.jpg/m/2614x1508',
        },
      ],
      */
    }
  },

  computed: {
    panoramaHeroBlok() {
      return {
        image_source_landscape: this.blok.image_landscape,
        image_source_portrait: this.blok.image_portrait,
      }
    },
    panoramaHeroTeaserBlok() {
      return {
        headline: getFakedRichtextObjectbyString(this.blok.title),
        text: getFakedRichtextObjectbyString(this.blok.subline),
        primary_link_label: '',
        secondary_link_label: '',
      }
    },
    imageLandscape() {
      return this.blok.image_landscape?.id !== null
        ? this.blok.image_landscape
        : null
    },
    imagePortrait() {
      return this.blok.image_portrait?.id !== null
        ? this.blok.image_portrait
        : null
    },
    category() {
      let category = null

      if (Array.isArray(this.blok.category) && this.blok.category.length) {
        if (
          typeof this.blok.category[0] === 'object' &&
          this.blok.category[0].uuid
        ) {
          category = this.blok.category[0]
        }
      }

      return category
    },
    author() {
      let author = null

      if (typeof this.blok.author === 'object' && this.blok.author.uuid) {
        author = this.blok.author.content
      }

      if (author.profile_link) {
        author = Object.assign({}, author, {
          profile_link: getStoryblokLink(author.profile_link),
        })
      }

      return author
    },
    readingTime() {
      let readingTime = null

      if (this.blok.reading_time) {
        readingTime =
          this.blok.reading_time +
          ' ' +
          (this.blok.reading_time === 1 ? 'Minute' : 'Minuten')
      }

      return readingTime
    },
    creationDate() {
      let creationDate = null

      if (this.blok.creation_date) {
        creationDate = format(parseISO(this.blok.creation_date), 'dd.MM.yyyy')
      }

      return creationDate
    },
    intro() {
      const storyblokApi = useStoryblokApi()

      return this.blok.intro
        ? storyblokApi.richTextResolver.render(this.blok.intro)
        : null
    },
  },

  methods: {
    getRelatedArticles() {
      const localeConfig = getLocaleConfigByPath(this.rootStory.full_slug)
      return _getRelatedArticles(
        this.blok.related_articles,
        this.blok.category.map((c) => c.uuid),
        this.blok.tags,
        [this.rootStory.id],
        !this.blok.no_related_articles_automation,
        localeConfig,
        6,
        this.$nuxt.context.env.CONTENT_VERSION,
        this.$nuxt.context.env.CONTENT_CACHE_VERSION
      ).then((stories) => stories.map((story) => getArticlePreview(story)))
    },
  },
}
