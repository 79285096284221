
const PRIMARY_NAVIGATION_VALID_HTML_TAGS = ['nav', 'div']
export default {
  name: 'PrimaryNavigation',

  props: {
    htmlTag: {
      type: String,
      required: false,
      default: 'nav',
      validator(tag) {
        return PRIMARY_NAVIGATION_VALID_HTML_TAGS.includes(tag)
      },
    },
    homeLink: {
      type: Object,
      required: false,
      default: null,
    },
    links: {
      type: Array,
      required: false,
      default: () => [],
    },
    vertical: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    componentTag() {
      return PRIMARY_NAVIGATION_VALID_HTML_TAGS.includes(this.htmlTag)
        ? this.htmlTag
        : PRIMARY_NAVIGATION_VALID_HTML_TAGS[0]
    },
  },
}
