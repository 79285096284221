
import { gsap } from '@gsap/shockingly/dist/gsap'
import PrimaryNavigation from './../primary-navigation/primary-navigation.vue'

export default {
  name: 'OffCanvasNavigation',

  components: {
    PrimaryNavigation,
  },

  props: {
    homeLink: {
      type: Object,
      required: false,
      default: null,
    },
    primaryNavigationLinks: {
      type: Array,
      required: false,
      default: () => [],
    },
    externalServicesNavigation: {
      type: Array,
      required: false,
      default: () => [],
    },
    externalServiceAd: {
      type: Object,
      required: false,
      default: null,
    },
    open: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    tabindex() {
      return this.open ? null : -1
    },
  },

  watch: {
    open(isOpen) {
      if (isOpen) {
        this.openOffCanvas()
      } else {
        this.closeOffCanvas()
      }
    },
  },

  beforeMount() {
    document.body.classList.remove('bc-off-canvas-navigation--open')
  },

  methods: {
    getTimeline() {
      const menuEl = this.$refs.menu
      const menuBackground = this.$refs.background

      const tl = gsap.timeline()

      tl.set(menuEl, { x: '100%' })
      tl.set(menuBackground, { display: 'block', autoAlpha: 0 })

      tl.to(menuEl, { x: 0, duration: 0.3 }, 0)
      tl.to(menuBackground, { autoAlpha: 1, duration: 0.3 }, 0)

      tl.pause()

      return tl
    },
    openOffCanvas() {
      const tl = this.getTimeline()
      tl.play().then(() => {
        document.body.classList.add('bc-off-canvas-navigation--open')
      })
    },
    closeOffCanvas() {
      const menuEl = this.$refs.menu
      const menuBackground = this.$refs.background

      const tl = this.getTimeline()
      tl.reverse(0).then(() => {
        document.body.classList.remove('bc-off-canvas-navigation--open')
        gsap.set([menuEl, menuBackground], {
          clearProps: 'all',
        })
      })
    },
  },
}
