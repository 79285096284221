
export default {
  name: 'CswThemeSwitch',

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      isChecked: this.value,
    }
  },

  computed: {
    themeSwitchClasses() {
      return {
        'csw-theme-switch--light': this.isChecked,
      }
    },
  },

  watch: {
    value(value) {
      this.isChecked = value
    },
    isChecked(isChecked) {
      if (this.value !== isChecked) {
        this.$emit('change', isChecked)
      }
    },
  },
}
