
export default {
  name: 'BlogNavigation',
  model: {
    event: 'change',
    prop: 'selectedId',
  },

  props: {
    links: {
      type: Array,
      required: true,
    },
    selectedId: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      buttonSizes: [],
      isSticky: false,
      isAnimating: false,
      hasGradientLeft: false,
      hasGradientRight: false,
      isWaiting: true,
      isDown: false,
      startX: 0,
      scrollLeft: 0,
      isLeftAligned: false,
    }
  },

  computed: {
    classNames() {
      return {
        'blog-navigation--sticky': this.isSticky,
        'blog-navigation--animating': this.isAnimating,
        'blog-navigation--gradient-left': this.hasGradientLeft,
        'blog-navigation--gradient-right': this.hasGradientRight,
        'blog-navigation--left-aligned': this.isLeftAligned,
      }
    },

    currentSelectedId() {
      return this.selectedId
    },

    activeElementProperties() {
      let properties = null
      if (this.currentSelectedId !== null && this.buttonSizes.length) {
        properties = {
          offsetLeft: 0,
          width: 0,
        }
        let selectedIndex = this.links.findIndex(
          (link) => link.id === this.currentSelectedId
        )
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex
        for (let i = 0; i <= selectedIndex - 1; i++) {
          properties.offsetLeft += this.buttonSizes[i]
        }
        if (selectedIndex > 0) {
          properties.offsetLeft += selectedIndex * 32
        }
        properties.width = this.buttonSizes[selectedIndex]
      }
      return properties
    },
  },

  watch: {
    activeElementProperties: {
      handler(newValue, oldValue) {
        if (oldValue === null) {
          this.$nextTick(() => {
            // TODO: this is hacky, we will try to solve that by another way later
            setTimeout(() => {
              this.isAnimating = true
            }, 300)
          })
        }
      },
    },

    selectedId: {
      handler() {
        const selectedIndex = this.links.findIndex(
          (link) => link.id === this.currentSelectedId
        )

        if (selectedIndex > -1) {
          const itemRef = this.$refs.items[selectedIndex]
          const wrapperRef = this.$refs.wrapper
          const padding = this.$refs.innerWrapper.offsetLeft
          let scrollX = null

          if (
            itemRef.offsetLeft + padding + itemRef.offsetWidth >
            wrapperRef.offsetWidth
          ) {
            scrollX = Math.abs(
              wrapperRef.offsetWidth -
                (itemRef.offsetLeft + itemRef.offsetWidth + padding * 2)
            )
          } else if (itemRef.offsetLeft < wrapperRef.scrollLeft) {
            // left side
            scrollX = itemRef.offsetLeft
          }

          if (scrollX !== null) {
            wrapperRef.scrollTo({
              left: scrollX,
              behavior: 'smooth',
            })
          }
        }
      },
    },
  },

  mounted() {
    document.fonts.ready.then(() => {
      this.addResizeObserver()
      this.addIntersectionObserver()
      this.addEventListeners()
      this.setGradients()
    })
  },

  beforeDestroy() {
    this.removeIntersectionObserver()
    this.removeResizeObserver()
    this.removeEventListeners()
  },

  methods: {
    setAlignment() {
      const outerWrapper = this.$refs.wrapper
      const innerWrapper = this.$refs.innerWrapper
      const padding = parseFloat(
        getComputedStyle(outerWrapper).getPropertyValue('padding-left')
      )

      this.isLeftAligned =
        innerWrapper.offsetWidth + padding * 2 > outerWrapper.offsetWidth
    },
    onMouseDown(e) {
      this.isDown = true
      this.startX = e.pageX - this.$refs.wrapper.offsetLeft
      this.scrollLeft = this.$refs.wrapper.scrollLeft
      this.addTimer()
    },
    onMouseLeave() {
      this.isDown = false
      this.isWaiting = true
    },
    onMouseUp(e) {
      this.isDown = false
      this.isWaiting = true
      e.preventDefault()
      e.stopImmediatePropagation()
    },
    onMouseMove(e) {
      if (!this.isDown) return
      e.preventDefault()
      const x = e.pageX - this.$refs.wrapper.offsetLeft
      const walk = x - this.startX
      this.$refs.wrapper.scrollLeft = this.scrollLeft - walk
    },
    addEventListeners() {
      const el = this.$refs.wrapper

      // gradients
      el.addEventListener('scroll', this.setGradients)

      // horizontal scroll
      el.addEventListener('mousedown', this.onMouseDown)
      el.addEventListener('mouseleave', this.onMouseLeave)
      el.addEventListener('mouseup', this.onMouseUp)
      el.addEventListener('mousemove', this.onMouseMove)
    },
    removeEventListeners() {
      const el = this.$refs.wrapper

      el.removeEventListener('scroll', this.setGradients)
      el.removeEventListener('mousedown', this.onMouseDown)
      el.removeEventListener('mouseleave', this.onMouseLeave)
      el.removeEventListener('mouseup', this.onMouseUp)
      el.removeEventListener('mousemove', this.onMouseMove)
    },
    addTimer() {
      setTimeout(() => {
        this.isWaiting = false
        if (this.isDown === false) {
          this.isWaiting = true
        }
      }, 300)
    },

    addResizeObserver() {
      this.resizeObserverInstance = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentBoxSize) {
            this.setButtonSizes()
            this.setAlignment()
            this.setGradients()
          }
        }
      })

      this.resizeObserverInstance.observe(this.$el)
    },
    removeResizeObserver() {
      this.resizeObserverInstance.disconnect()
    },
    setGradients() {
      const el = this.$refs.wrapper
      this.hasGradientLeft = el.scrollLeft !== 0
      this.hasGradientRight =
        Math.ceil(el.scrollLeft + el.offsetWidth) < el.scrollWidth
    },
    addIntersectionObserver() {
      const options = {
        root: document,
        rootMargin: '-1px 0px 65px 0px',
        threshold: 1,
      }

      this.intersectionObserverInstance = new IntersectionObserver(
        (entries) => {
          this.isSticky = entries[0].isIntersecting === false
        },
        options
      )

      this.intersectionObserverInstance.observe(this.$refs.scrollSection)
    },
    removeIntersectionObserver() {
      this.intersectionObserverInstance.disconnect()
    },
    setButtonSizes() {
      this.buttonSizes = this.$refs.items.map((navigation) => {
        return navigation.offsetWidth
      })
    },
  },
}
