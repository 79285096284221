
import { getLocaleConfigByPath } from './../../utils/storyblok-helper'
import { getArticlePreview } from './../../utils/article-helper'
import { getRelatedArticles as _getRelatedArticles } from './../../requests/articles'

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
    rootStory: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      relatedArticles: [],
    }
  },

  async fetch() {
    if (this.blok.related_blog_articles) {
      this.relatedArticles = await this.getRelatedArticles()
    }
  },

  methods: {
    getRelatedArticles() {
      const localeConfig = getLocaleConfigByPath(this.rootStory.full_slug)

      return _getRelatedArticles(
        this.blok.related_blog_articles,
        this.blok.related_blog_category,
        this.blok.related_blog_tags,
        [],
        !this.blok.no_related_blog_articles_automation,
        localeConfig,
        6,
        this.$nuxt.context.env.CONTENT_VERSION,
        this.$nuxt.context.env.CONTENT_CACHE_VERSION
      ).then((stories) => stories.map((story) => getArticlePreview(story)))
    },
  },
}
