export const setIsAllowedToLoad = ({ commit }, isAllowedToLoad) => {
  commit('setIsAllowedToLoad', isAllowedToLoad)
}

export const setHasConversations = ({ commit }, hasConversations) => {
  commit('setHasConversations', hasConversations)
}

export default {
  setIsAllowedToLoad,
  setHasConversations,
}
