export const domain = 'https://www.cs-webdevelopment.com'

export const assetDomain = 'https://assets.cs-webdevelopment.com'

export const storyblokAssetDomain = 'https://a.storyblok.com/f/60148'

export const locales = [
  {
    slug: 'de-de',
    code: 'de-DE',
    alternateCode: 'de_DE',
    shortcutLabel: 'DE',
  },
  /*
  {
    slug: 'en-us',
    code: 'en-US',
    alternateCode: 'en_US',
    shortcutLabel: 'EN',
  },
  */
]

export default {
  domain,
  assetDomain,
  storyblokAssetDomain,
  locales,
}
