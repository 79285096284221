
import { gsap } from '@gsap/shockingly/dist/gsap'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
import { getAssetUrl, getStoryblokLink } from './../../utils/storyblok-helper'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'CmpChatContact',

  props: {
    blok: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    defaultImage() {
      let defaultImage = null

      if (this.blok.image_source_portrait.filename) {
        defaultImage = {
          src: getAssetUrl(
            this.blok.image_source_portrait.filename,
            '619x928/filters:quality(60)'
          ),
          alt: this.blok.image_source_portrait.alt,
        }
      }

      return defaultImage
    },

    portraitSources() {
      let sources = []

      if (this.blok.image_source_portrait.filename) {
        const getResolvedAssetUrl = (width) => {
          const height = Math.round((16 / 9) * width)

          return getAssetUrl(
            this.blok.image_source_portrait.filename,
            width + 'x' + height + '/filters:quality(80)'
          )
        }

        sources = [
          {
            media: '(min-width: 767px)',
            srcset: getResolvedAssetUrl(403),
          },
          {
            media: '(min-width: 1024px)',
            srcset: getResolvedAssetUrl(537),
          },
          {
            media: '(min-width: 1280px)',
            srcset: getResolvedAssetUrl(672),
          },
          {
            media: '(min-width: 1440px)',
            srcset: getResolvedAssetUrl(756),
          },
          {
            media: '(min-width: 1920px)',
            srcset: getResolvedAssetUrl(1007),
          },
        ]
      }

      return sources.reverse()
    },

    label() {
      return this.blok.label || ''
    },

    email() {
      return {
        label:
          this.blok.email_label || this.settings.content.contact_email_label,
        email: this.blok.email || this.settings.content.contact_email,
        href:
          'mailto:' + (this.blok.email || this.settings.content.contact_email),
      }
    },

    phone() {
      return {
        label:
          this.blok.phone_label || this.settings.content.contact_phone_label,
        phone: this.blok.phone || this.settings.content.contact_phone,
        href:
          'tel:' +
          (this.blok.phone || this.settings.content.contact_phone).replace(
            /[^0-9]/g,
            ''
          ),
      }
    },

    address() {
      return {
        label:
          this.blok.address_label ||
          this.settings.content.contact_address_label,
        address: this.blok.address || this.settings.content.contact_address,
        href:
          'http://maps.google.com/?q=' +
          (this.blok.address || this.settings.content.contact_address),
      }
    },

    headline() {
      let headline

      if (this.blok.headline) {
        headline = {
          label: this.blok.headline,
          tag: this.blok.headline_tag || 'div',
        }
      }

      return headline
    },

    text() {
      return this.blok.text || null
    },

    primaryLink() {
      let content = null

      if (this.blok.primary_link_label !== '') {
        content = {
          link: getStoryblokLink(this.blok.primary_link) || '',
          icon: this.blok.primary_link_icon || '',
          label: this.blok.primary_link_label || '',
        }
      }
      return content
    },
  },

  mounted() {
    document.fonts.ready.then(() => {
      this.createAnimations()
    })
  },

  methods: {
    createAnimations() {
      const content = this.$refs.content
      const image = this.$refs.image

      gsap
        .timeline({
          scrollTrigger: {
            trigger: content,
            start: 'top bottom',
            end: 'bottom bottom',
            scrub: true,
            markers: false,
          },
        })
        .fromTo(
          image,
          {
            yPercent: 25,
          },
          {
            yPercent: 0,
            ease: 'sine',
            duration: 0.5,
            stagger: 0.5,
          },
          0
        )

      if (this.$refs.teaser) {
        gsap
          .timeline({
            scrollTrigger: {
              trigger: this.$refs.teaser,
              start: 'top bottom',
              end: 'bottom bottom',
              scrub: true,
              markers: false,
            },
          })
          .fromTo(
            this.$refs.teaser.children,
            {
              yPercent: 75,
            },
            {
              yPercent: 0,
              ease: 'expo',
              duration: 0.5,
              stagger: 0.5,
            },
            0
          )
      }
    },
  },
}
