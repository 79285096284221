export const hideNotification = ({ commit, state, getters }, notification) => {
  const expiredNotifications = state.hidden.filter((d) => {
    return !getters.hiddenNotificationIds.includes(d.id)
  })

  if (expiredNotifications.length) {
    commit('deleteNotifications', expiredNotifications)
  }

  if (
    state.hidden.find(
      (d) => d.id === notification.id && d.mode === notification.mode
    ) === undefined
  ) {
    commit(
      'hideNotification',
      Object.assign({}, notification, {
        createdAt: new Date().getTime(),
      })
    )
  }
}

export default {
  hideNotification,
}
