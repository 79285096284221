
export default {
  name: 'CswModal',

  model: {
    prop: 'value',
    event: 'change',
  },

  props: {
    value: {
      type: Boolean,
      require: true,
    },
  },

  watch: {
    value() {
      this.handleScrollBlocker()
    },
  },

  mount() {
    this.handleScrollBlocker()
  },

  methods: {
    close() {
      this.$emit('close')
    },
    handleScrollBlocker() {
      if (this.value) {
        document.body.classList.add('csw-modal-open')
      } else {
        document.body.classList.remove('csw-modal-open')
      }
    },
  },
}
