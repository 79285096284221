
import { useStoryblokApi } from '@storyblok/nuxt'

export default {
  name: 'CmpAdvancedTextBlock',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      const storyblokApi = useStoryblokApi()

      return this.blok.text
        ? storyblokApi.richTextResolver.render(this.blok.text)
        : null
    },
  },
}
