
export default {
  name: 'ExternalServiceAd',

  props: {
    kicker: {
      type: String,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    iconComponentTag() {
      return 'icon-' + this.icon
    },
  },
}
