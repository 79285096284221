
import VimeoPlayer from '@vimeo/player'
import { useStoryblokApi } from '@storyblok/nuxt'
import { mapGetters } from 'vuex'
import { getIncrementedNumber } from '../../utils/increment'

export default {
  props: {
    blok: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      hasPrivacyPolicyConfirmed: null,
      player: null,
      playerId: null,
      videoTitle: null,
      hasOpenDialog: false,
    }
  },

  fetch() {
    this.playerId = 'vimeo-player-' + getIncrementedNumber()
  },

  computed: {
    ...mapGetters('core', ['consent']),
    caption() {
      const storyblokApi = useStoryblokApi()

      return this.blok.caption
        ? storyblokApi.richTextResolver.render(this.blok.caption)
        : this.videoTitle
    },
    dataPrivacyNote() {
      const storyblokApi = useStoryblokApi()

      return storyblokApi.richTextResolver.render(
        this.settings.content.vimeo_dp_note
      )
    },
    dataPrivacyInformation() {
      const storyblokApi = useStoryblokApi()

      return storyblokApi.richTextResolver.render(
        this.settings.content.vimeo_dp_information
      )
    },
  },

  watch: {
    'consent.vimeo': {
      handler(hasConsent) {
        this.handleConsent(hasConsent)
      },
    },
  },

  mounted() {
    this.handleConsent(this.consent.vimeo)
  },

  beforeDestroy() {
    this.removePlayer()
  },

  methods: {
    handleConsent(hasConsent) {
      if (hasConsent === true) {
        this.hasPrivacyPolicyConfirmed = true
        this.addPlayer()
      } else {
        this.removePlayer()
        this.hasPrivacyPolicyConfirmed = false
      }

      this.closeDialog()
    },
    async addPlayer() {
      if (this.player === null) {
        const options = {
          id: this.blok.videoId,
          byline: false,
          title: this.blok.showTitle,
          color: getComputedStyle(document.documentElement)
            .getPropertyValue('--csw-cmp-vimeo-controls-color')
            .trim(),
          responsive: true,
        }

        this.player = new VimeoPlayer(this.playerId, options)
        this.videoTitle = await this.player.getVideoTitle()
      }

      return this.player
    },
    removePlayer() {
      return new Promise((resolve) => {
        if (this.player) {
          this.player.destroy().then(() => {
            this.player = null
            resolve()
          })
        } else {
          resolve()
        }
      })
    },
    openDialog() {
      this.hasOpenDialog = true
    },
    closeDialog() {
      this.hasOpenDialog = false
    },
    openCookieSettings() {
      window.openCookieSettings()
    },
    acceptCookiesAndLoadVideo() {
      window.acceptCmpServiceByName('Vimeo')
    },
  },
}
