
// highlightjs
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import typescript from 'highlight.js/lib/languages/typescript'
import css from 'highlight.js/lib/languages/css'
import scss from 'highlight.js/lib/languages/scss'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
import { init as initLineNumbers } from './../../utils/hljs-line-numbers'

hljs.registerLanguage('javascript', javascript)
hljs.registerLanguage('typescript', typescript)
hljs.registerLanguage('css', css)
hljs.registerLanguage('scss', scss)
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('json', json)

export default {
  name: 'CmpCodeBlock',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    codeHtml() {
      return this.blok.code
    },
    languageClass() {
      let languageClass = null

      if (this.blok.language) {
        languageClass = 'language-' + this.blok.language
      }

      return languageClass
    },
  },

  watch: {
    'blok.code'() {
      this.$nextTick(() => {
        this.applyHighlightJs()
      })
    },
  },

  mounted() {
    this.applyHighlightJs()
  },

  methods: {
    applyHighlightJs() {
      const codeEl = this.$el.querySelector('code')
      hljs.highlightElement(codeEl)
      initLineNumbers(hljs)
      hljs.lineNumbersBlock(codeEl)
    },
  },
}
