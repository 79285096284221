export default () => ({
  theme: 'dark',
  consent: {
    vimeo: false,
    ga4: false,
  },
  tracking: {
    measurementId:
      process.env.DEPLOYMENT_TARGET === 'production'
        ? 'G-E219LLMNWV'
        : 'G-MVN2NDVQQG',
  },
})
