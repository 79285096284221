import { parseISO, format } from 'date-fns'
import { getSanitizedFullSlug } from './url-helper'

export const getArticlePreview = (story) => {
  return {
    id: story.id,
    uuid: story.uuid,
    date: format(parseISO(story.content.creation_date), 'yyyy-MM-dd'),
    formattedDate: format(parseISO(story.content.creation_date), 'dd.MM.yyyy'),
    href: getSanitizedFullSlug(story.full_slug),
    title: story.content.title,
    subline: story.content.subline,
  }
}

export default {
  getArticlePreview,
}
