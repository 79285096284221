
export default {
  name: 'BlogFact',

  props: {
    label: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: null,
    },
  },
}
