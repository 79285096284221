
import { useStoryblokApi } from '@storyblok/nuxt'
import adLinkMixin from '../../mixins/ad-link.mixin.js'

export default {
  name: 'CmpTextBlock',

  mixins: [adLinkMixin],

  props: {
    blok: {
      type: Object,
      required: true,
    },
    settings: {
      type: Object,
      required: true,
    },
  },

  computed: {
    content() {
      const storyblokApi = useStoryblokApi()

      return this.blok.text
        ? storyblokApi.richTextResolver.render(this.blok.text)
        : null
    },
    isSmall() {
      return this.blok.size === 's'
    },
  },
}
