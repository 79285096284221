
export default {
  name: 'CswButton',

  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    anchor: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    secondary: {
      type: Boolean,
      required: false,
      default: false,
    },
    ghost: {
      type: Boolean,
      required: false,
      default: false,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
    iconPosition: {
      type: String,
      required: false,
      default: 'right',
      validator: (position) => {
        return ['left', 'right'].includes(position)
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    linkProps() {
      let props = {}

      if (this.href !== null) {
        if (this.isExternalLink) {
          props = {
            href: this.href,
            target: this.target,
            rel: 'nofollow noopener',
          }
        } else {
          props = {
            to: { path: this.href, hash: this.anchor },
          }
        }
      }

      return props
    },
    componentTag() {
      return this.href === null
        ? 'button'
        : this.isExternalLink
        ? 'a'
        : 'NuxtLink'
    },
    iconComponentTag() {
      let tag = null

      if (typeof this.icon === 'string') {
        tag = 'icon-' + this.icon
      }

      return tag
    },
    buttonClasses() {
      return {
        'csw-button--secondary': this.secondary,
        'csw-button--ghost': this.ghost,
        'csw-button--large': this.large,
        'csw-button--disabled': this.disabled,
        ['csw-button--icon-' + this.iconPosition]: this.icon,
      }
    },
    isExternalLink() {
      return (
        this.href !== null &&
        typeof this.target === 'string' &&
        this.target.toLowerCase() === '_blank'
      )
    },
  },
}
