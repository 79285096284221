
import { gsap } from '@gsap/shockingly/dist/gsap'
import { DrawSVGPlugin } from '@gsap/shockingly/dist/DrawSVGPlugin'
import { ScrollTrigger } from '@gsap/shockingly/dist/ScrollTrigger'
gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger)

export default {
  name: 'CmpExpressiveHeadline',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    headlines() {
      const headlines = []

      if (
        Array.isArray(this.blok.headline.content) &&
        this.blok.headline.content.length &&
        Array.isArray(this.blok.headline.content[0].content)
      ) {
        this.blok.headline.content[0].content
          .filter((o) => o.type === 'text')
          .forEach((o) => {
            headlines.push(o.text)
          })
      }

      return headlines
    },
  },
}
