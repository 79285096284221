
export default {
  name: 'CategoryArticleTeaser',

  props: {
    articles: {
      type: Array,
      required: true,
    },
    headlineLines: {
      type: Array,
      required: true,
    },
    moreArticlesLinkLabel: {
      type: String,
      required: true,
    },
    moreArticlesLinkHref: {
      type: String,
      required: true,
    },
  },

  computed: {
    classNames() {
      return {
        'category-article-teaser--with-picture':
          this.articles.length && this.articles[0].imageSources.length,
      }
    },
  },

  mounted() {
    this.$refs.computedHeadline.startAnimation()
  },
}
