import Vue from 'vue'

// Icons
import IconShareCircleLine from '@/assets/icons/share-circle-line.svg?inline'
import IconUserAddFill from '@/assets/icons/user-add-fill.svg?inline'
import IconUserVoiceFill from '@/assets/icons/user-voice-fill.svg?inline'
import IconUserSharedFill from '@/assets/icons/user-shared-fill.svg?inline'
import IconUserStarFill from '@/assets/icons/user-star-fill.svg?inline'
import IconUserFill from '@/assets/icons/user-fill.svg?inline'
import IconTeamFill from '@/assets/icons/team-fill.svg?inline'
import IconMoonFill from '@/assets/icons/moon-fill.svg?inline'
import IconMoonLine from '@/assets/icons/moon-line.svg?inline'
import IconSunFill from '@/assets/icons/sun-fill.svg?inline'
import IconSunLine from '@/assets/icons/sun-line.svg?inline'
import IconTranslate from '@/assets/icons/translate.svg?inline'
import IconLogo from '@/assets/icons/logo.svg?inline'
import IconMenu from '@/assets/icons/menu.svg?inline'
import IconListIndicator from '@/assets/icons/list-indicator.svg?inline'
import IconNavArrowLeft from '@/assets/icons/nav-arrow-left.svg?inline'
import IconNavArrowRight from '@/assets/icons/nav-arrow-right.svg?inline'
import IconSmallArrowDown from '@/assets/icons/small-arrow-down.svg?inline'
import IconSmallArrowUp from '@/assets/icons/small-arrow-up.svg?inline'
import IconFacebookComb from '@/assets/icons/facebook-comb.svg?inline'
import IconInstagramComb from '@/assets/icons/instagram-comb.svg?inline'
import IconGithubComb from '@/assets/icons/github-comb.svg?inline'
import IconLinkedinComb from '@/assets/icons/linkedin-comb.svg?inline'
import IconLinkedin from '@/assets/icons/linkedin.svg?inline'
import IconClose from '@/assets/icons/close.svg?inline'
import IconShareLine from '@/assets/icons/share-line.svg?inline'
import IconLink from '@/assets/icons/link.svg?inline'
import IconArrowRight from '@/assets/icons/arrow-right.svg?inline'
import IconArrowRightCircleFill from '@/assets/icons/arrow-right-circle-fill.svg?inline'
import IconInformationFill from '@/assets/icons/information-fill.svg?inline'
import IconApps from '@/assets/icons/apps.svg?inline'
import IconBraces from '@/assets/icons/braces.svg?inline'
import IconCustomerService2 from '@/assets/icons/customer-service-2.svg?inline'
import IconEqualizer from '@/assets/icons/equalizer.svg?inline'
import IconFocus3Fill from '@/assets/icons/focus-3-fill.svg?inline'
import IconLayout5 from '@/assets/icons/layout-5.svg?inline'
import IconQuestionnaire from '@/assets/icons/questionnaire.svg?inline'
import IconShoppingCart2 from '@/assets/icons/shopping-cart-2.svg?inline'
import IconHome5Line from '@/assets/icons/home-5-line.svg?inline'
import IconInterfaceSettingMenu3 from '@/assets/icons/interface-setting-menu-3.svg?inline'
import IconRightUpLine from '@/assets/icons/right-up-line.svg?inline'
import IconIndeterminateCircleFill from '@/assets/icons/indeterminate-circle-fill.svg?inline'
import IconAddCircleLine from '@/assets/icons/add-circle-line.svg?inline'

// technical brands
import IconBrandJamstack from '@/assets/icons/technical-brands/jamstack.svg?inline'
import IconBrandLit from '@/assets/icons/technical-brands/lit.svg?inline'
import IconBrandMongodb from '@/assets/icons/technical-brands/mongodb.svg?inline'
import IconBrandNodejs from '@/assets/icons/technical-brands/nodejs.svg?inline'
import IconBrandPostgresql from '@/assets/icons/technical-brands/postgresql.svg?inline'
import IconBrandStoryblok from '@/assets/icons/technical-brands/storyblok.svg?inline'
import IconBrandTypescript from '@/assets/icons/technical-brands/typescript.svg?inline'
import IconBrandVuejs from '@/assets/icons/technical-brands/vuejs.svg?inline'
import IconBrandHtml5 from '@/assets/icons/technical-brands/html5.svg?inline'
import IconBrandCss3 from '@/assets/icons/technical-brands/css3.svg?inline'
import IconBrandJavascript from '@/assets/icons/technical-brands/javascript.svg?inline'
import IconBrandNuxt from '@/assets/icons/technical-brands/nuxt.svg?inline'

// other special icons
import IconTrainingIhk from '@/assets/icons/training-ihk.svg?inline'

// UI
import CswButton from '@/components/ui/button/csw-button.vue'
import CswTextLink from '@/components/ui/text-link/csw-text-link.vue'
import CswMenuLink from '@/components/ui/menu-link/csw-menu-link.vue'
import CswBackToTopLink from '@/components/ui/back-to-top-link/csw-back-to-top-link.vue'
import CswHeadline from '@/components/ui/headline/csw-headline.vue'
import CswThemeSwitch from '@/components/ui/theme-switch/csw-theme-switch.vue'
import CswLanguageSwitch from '@/components/ui/language-switch/csw-language-switch.vue'
import CswExternalServiceLink from '@/components/ui/external-service-link/csw-external-service-link.vue'
import CswLogo from '@/components/ui/logo/csw-logo.vue'
import CswImage from '@/components/ui/image/csw-image.vue'
import CswChip from '@/components/ui/chip/csw-chip.vue'
import CswModal from '@/components/ui/modal/csw-modal.vue'
import CswModalContent from '@/components/ui/modal/csw-modal-content.vue'

// Layouts
import Layout from '@/components/layout/layout.vue'

// Content Types
import Page from '@/components/page/page.vue'
import BlogHomepage from '@/components/blog-homepage/blog-homepage.vue'
import BlogCategory from '@/components/blog-category/blog-category.vue'
import BlogArticle from '@/components/blog-article/blog-article.vue'

// Components that are only internally embedded
import ArticleTeaser from '@/components/article-teaser/article-teaser.vue'
import CategoryArticleTeaser from '@/components/category-article-teaser/category-article-teaser.vue'
import BlogNavigation from '@/components/blog-navigation/blog-navigation.vue'
import Richtext from '@/components/richtext/richtext.vue'
import LinkResolver from '@/components/link-resolver/link-resolver.vue'
import ComputedHeadline from '@/components/computed-headline/computed-headline.vue'
import ExternalServiceAd from '@/components/external-service-ad/external-service-ad.vue'

// Components based on :blok property as input
import CmpShoutOutHero from '@/components/shout-out-hero/shout-out-hero.vue'
import CmpHeroTeaser from '@/components/hero-teaser/hero-teaser.vue'
import CmpShyHero from '@/components/shy-hero/shy-hero.vue'
import CmpShyHero2 from '@/components/shy-hero-2/shy-hero-2.vue'
import CmpProductStage from '@/components/product-stage/product-stage.vue'
import CmpVersusTeasers from '@/components/versus-teasers/versus-teasers.vue'
import CmpFeatureListHero from '@/components/feature-list-hero/feature-list-hero.vue'
import CmpFlexibleTextContent from '@/components/flexible-text-content/flexible-text-content.vue'
import CmpExtendedFeatureList from '@/components/extended-feature-list/extended-feature-list.vue'
import CmpTextBlock from '@/components/text-block/text-block.vue'
import CmpAdvancedTextBlock from '@/components/advanced-text-block/advanced-text-block.vue'
import CmpQuoteBlock from '@/components/quote-block/quote-block.vue'
import CmpCodeBlock from '@/components/code-block/code-block.vue'
import CmpSourceReference from '@/components/source-reference/source-reference.vue'
import CmpImage from '@/components/image/image.vue'
import CmpMediaGrid from '@/components/media-grid/media-grid.vue'
import CmpListBlock from '@/components/list-block/list-block.vue'
import CmpSection from '@/components/section/section.vue'
import CmpLargeDecoratedImage from '@/components/large-decorated-image/large-decorated-image.vue'
import CmpChatContact from '@/components/chat-contact/chat-contact.vue'
import CmpUspComb from '@/components/usp-comb/usp-comb.vue'
import CmpPanoramaHero from '@/components/panorama-hero/panorama-hero.vue'
import CmpSteppedContent from '@/components/stepped-content/stepped-content.vue'
import CmpVimeo from '@/components/vimeo/vimeo.vue'
import CmpLinkTileGrid from '@/components/link-tile-grid/link-tile-grid.vue'
import CmpContentSlider from '@/components/content-slider/content-slider'
import CmpContentSliderItem from '@/components/content-slider-item/content-slider-item'
import CmpBrandGrid from '@/components/brand-grid/brand-grid'
import CmpExpressiveHeadline from '@/components/expressive-headline/expressive-headline'
import CmpAccordion from '@/components/accordion/accordion'
import CmpCalendly from '@/components/calendly/calendly'

// Within Components
import WcmpText from '@/components/wcmp-text/wcmp-text.vue'
import WcmpTextList from '@/components/wcmp-text-list/wcmp-text-list.vue'
import WcmpTags from '@/components/wcmp-tags/wcmp-tags.vue'

// Icons
Vue.component('IconShareCircleLine', IconShareCircleLine)
Vue.component('IconUserAddFill', IconUserAddFill)
Vue.component('IconUserVoiceFill', IconUserVoiceFill)
Vue.component('IconUserFill', IconUserFill)
Vue.component('IconUserStarFill', IconUserStarFill)
Vue.component('IconUserSharedFill', IconUserSharedFill)
Vue.component('IconTeamFill', IconTeamFill)
Vue.component('IconMoonFill', IconMoonFill)
Vue.component('IconMoonLine', IconMoonLine)
Vue.component('IconSunLine', IconSunLine)
Vue.component('IconSunFill', IconSunFill)
Vue.component('IconTranslate', IconTranslate)
Vue.component('IconLogo', IconLogo)
Vue.component('IconMenu', IconMenu)
Vue.component('IconListIndicator', IconListIndicator)
Vue.component('IconNavArrowLeft', IconNavArrowLeft)
Vue.component('IconNavArrowRight', IconNavArrowRight)
Vue.component('IconSmallArrowDown', IconSmallArrowDown)
Vue.component('IconSmallArrowUp', IconSmallArrowUp)
Vue.component('IconFacebookComb', IconFacebookComb)
Vue.component('IconInstagramComb', IconInstagramComb)
Vue.component('IconGithubComb', IconGithubComb)
Vue.component('IconLinkedinComb', IconLinkedinComb)
Vue.component('IconLinkedin', IconLinkedin)
Vue.component('IconClose', IconClose)
Vue.component('IconShareLine', IconShareLine)
Vue.component('IconLink', IconLink)
Vue.component('IconArrowRight', IconArrowRight)
Vue.component('IconArrowRightCircleFill', IconArrowRightCircleFill)
Vue.component('IconInformationFill', IconInformationFill)
Vue.component('IconApps', IconApps)
Vue.component('IconBraces', IconBraces)
Vue.component('IconCustomerService2', IconCustomerService2)
Vue.component('IconEqualizer', IconEqualizer)
Vue.component('IconFocus3Fill', IconFocus3Fill)
Vue.component('IconLayout5', IconLayout5)
Vue.component('IconQuestionnaire', IconQuestionnaire)
Vue.component('IconShoppingCart2', IconShoppingCart2)
Vue.component('IconHome5Line', IconHome5Line)
Vue.component('IconInterfaceSettingMenu3', IconInterfaceSettingMenu3)
Vue.component('IconRightUpLine', IconRightUpLine)
Vue.component('IconIndeterminateCircleFill', IconIndeterminateCircleFill)
Vue.component('IconAddCircleLine', IconAddCircleLine)

// technical brands
Vue.component('IconBrandJamstack', IconBrandJamstack)
Vue.component('IconBrandLit', IconBrandLit)
Vue.component('IconBrandMongodb', IconBrandMongodb)
Vue.component('IconBrandNodejs', IconBrandNodejs)
Vue.component('IconBrandPostgresql', IconBrandPostgresql)
Vue.component('IconBrandStoryblok', IconBrandStoryblok)
Vue.component('IconBrandTypescript', IconBrandTypescript)
Vue.component('IconBrandVuejs', IconBrandVuejs)
Vue.component('IconBrandHtml5', IconBrandHtml5)
Vue.component('IconBrandCss3', IconBrandCss3)
Vue.component('IconBrandJavascript', IconBrandJavascript)
Vue.component('IconBrandNuxt', IconBrandNuxt)

// other special icons
Vue.component('IconTrainingIhk', IconTrainingIhk)

// UI
Vue.component('CswButton', CswButton)
Vue.component('CswTextLink', CswTextLink)
Vue.component('CswMenuLink', CswMenuLink)
Vue.component('CswBackToTopLink', CswBackToTopLink)
Vue.component('CswHeadline', CswHeadline)
Vue.component('CswThemeSwitch', CswThemeSwitch)
Vue.component('CswLanguageSwitch', CswLanguageSwitch)
Vue.component('CswExternalServiceLink', CswExternalServiceLink)
Vue.component('CswLogo', CswLogo)
Vue.component('CswImage', CswImage)
Vue.component('CswChip', CswChip)
Vue.component('CswModal', CswModal)
Vue.component('CswModalContent', CswModalContent)

// Layout
Vue.component('Layout', Layout)

// Content Types
Vue.component('Page', Page)
Vue.component('BlogHomepage', BlogHomepage)
Vue.component('BlogCategory', BlogCategory)
Vue.component('BlogArticle', BlogArticle)

// Components that are only internally embedded
Vue.component('ArticleTeaser', ArticleTeaser)
Vue.component('CategoryArticleTeaser', CategoryArticleTeaser)
Vue.component('BlogNavigation', BlogNavigation)
Vue.component('Richtext', Richtext)
Vue.component('LinkResolver', LinkResolver)
Vue.component('ComputedHeadline', ComputedHeadline)
Vue.component('ExternalServiceAd', ExternalServiceAd)

// Components based on :blok property as input
Vue.component('CmpAdvancedTextBlock', CmpAdvancedTextBlock)
Vue.component('CmpTextBlock', CmpTextBlock)
Vue.component('CmpQuoteBlock', CmpQuoteBlock)
Vue.component('CmpCodeBlock', CmpCodeBlock)
Vue.component('CmpSourceReference', CmpSourceReference)
Vue.component('CmpImage', CmpImage)
Vue.component('CmpMediaGrid', CmpMediaGrid)
Vue.component('CmpShoutOutHero', CmpShoutOutHero)
Vue.component('CmpProductStage', CmpProductStage)
Vue.component('CmpVersusTeasers', CmpVersusTeasers)
Vue.component('CmpHeroTeaser', CmpHeroTeaser)
Vue.component('CmpFeatureListHero', CmpFeatureListHero)
Vue.component('CmpShyHero', CmpShyHero)
Vue.component('CmpShyHero2', CmpShyHero2)
Vue.component('CmpFlexibleTextContent', CmpFlexibleTextContent)
Vue.component('CmpExtendedFeatureList', CmpExtendedFeatureList)
Vue.component('CmpListBlock', CmpListBlock)
Vue.component('CmpSection', CmpSection)
Vue.component('CmpLargeDecoratedImage', CmpLargeDecoratedImage)
Vue.component('CmpChatContact', CmpChatContact)
Vue.component('CmpUspComb', CmpUspComb)
Vue.component('CmpPanoramaHero', CmpPanoramaHero)
Vue.component('CmpSteppedContent', CmpSteppedContent)
Vue.component('CmpVimeo', CmpVimeo)
Vue.component('CmpLinkTileGrid', CmpLinkTileGrid)
Vue.component('CmpContentSlider', CmpContentSlider)
Vue.component('CmpContentSliderItem', CmpContentSliderItem)
Vue.component('CmpBrandGrid', CmpBrandGrid)
Vue.component('CmpExpressiveHeadline', CmpExpressiveHeadline)
Vue.component('CmpAccordion', CmpAccordion)
Vue.component('CmpCalendly', CmpCalendly)

// Within Components
Vue.component('WcmpText', WcmpText)
Vue.component('WcmpTextList', WcmpTextList)
Vue.component('WcmpTags', WcmpTags)
