
export default {
  name: 'CmpContentSliderItem',

  props: {
    blok: {
      type: Object,
      required: true,
    },
  },

  computed: {
    kicker() {
      return this.blok.kicker || ''
    },
    headline() {
      return this.blok.headline || ''
    },
    headlineTag() {
      return this.blok.headline_tag || 'div'
    },
    text() {
      return this.blok.text || {}
    },
  },
}
