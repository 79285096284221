export default {
  mounted() {
    this.$nextTick(() => {
      this.renderAdLinks()
    })
  },

  methods: {
    renderAdLinks() {
      const child = document.querySelectorAll('.csw-ad-link')

      child.forEach((element) => {
        element.parentElement.classList.add('csw-ad-link-wrapper')
      })
    },
  },
}
