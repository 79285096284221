
export default {
  name: 'BlogIntro',

  props: {
    content: {
      type: String,
      required: true,
    },
  },
}
