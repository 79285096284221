
export default {
  name: 'CswTextLink',

  props: {
    label: {
      type: String,
      required: true,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
    target: {
      type: String,
      required: false,
      default: null,
    },
    large: {
      type: Boolean,
      required: false,
      default: false,
    },
    // only for large buttons available
    iconLeft: {
      type: String,
      required: false,
      default: null,
    },
    // only for large buttons available
    iconRight: {
      type: String,
      required: false,
      default: null,
    },
    // used in navigation context
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
      default: null,
    },
  },

  computed: {
    linkProps() {
      let props = {}

      if (this.href !== null) {
        if (this.isExternalLink) {
          props = {
            href: this.href,
            target: this.target,
            rel: 'noopener',
          }
        } else {
          props = {
            to: this.href || '',
          }
        }
      }

      return props
    },
    linkComponentTag() {
      let tag

      if (this.href === null) {
        tag = 'button'
      } else if (this.isExternalLink) {
        tag = 'a'
      } else {
        tag = 'NuxtLink'
      }

      return tag
    },
    iconLeftComponentTag() {
      let tag = null

      if (typeof this.iconLeft === 'string' && this.large) {
        tag = 'icon-' + this.iconLeft
      }

      return tag
    },
    iconRightComponentTag() {
      let tag = null

      if (typeof this.iconRight === 'string' && this.large) {
        tag = 'icon-' + this.iconRight
      }

      return tag
    },
    textLinkClasses() {
      return {
        'csw-text-link--large': this.large,
        'csw-text-link--active': this.active,
        'csw-text-link--icon-left': this.iconLeft,
        'csw-text-link--icon-right': this.iconRight,
      }
    },
    isExternalLink() {
      return (
        this.href !== null &&
        typeof this.target === 'string' &&
        this.target.toLowerCase() === '_blank'
      )
    },
  },
}
